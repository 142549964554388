<div class="conteudo-exclusivo">
    <div class="conteudo-exclusivo-bg"></div>
    <div class="py-5">
        <div class="headerPage">
            <div class="headerPageFilter">
                <div>
                    <i class="bi bi-star-fill custom-icon" [style.color]="labelColor"></i>
                    <h1 class="main-title" [style.color]="labelColor">
                        Conteúdo Exclusivo
                    </h1>
                </div>

                <form class="search" [formGroup]="filterGroup">
                    <input type="text" placeholder="Pesquisar conteúdo" formControlName="filterControl"
                        (keyup)="filter()" />
                </form>
            </div>
            <div class="filters">
                <div class="ordernar-por mb-3">
                    <label>Ordenar por</label>
                    <select id="ordernarPor" [(ngModel)]="selectedOption" (change)="filterSelect()">
                        <option *ngFor="let option of options" [ngValue]="option.code">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                    <button (click)="filterType('video')" [ngClass]="{'activeButton': typeActive === 'video'}" class=" mb-3" *ngIf="checkShowFilter('video')">
                        <img src="/assets/svg/filmWhite.svg" alt="film icon">
                        <p>Vídeos</p>
                    </button>
                    <button (click)="filterType('image')" [ngClass]="{'activeButton': typeActive === 'image'}" class=" mb-3" *ngIf="checkShowFilter('image')">
                        <img src="/assets/svg/newspaperWhite.svg" alt="newspaper icon">
                        <p>Matérias</p>
                    </button>
                    <button (click)="filterType('article')" [ngClass]="{'activeButton': typeActive === 'article'}" class=" mb-3" *ngIf="checkShowFilter('article')">
                        <img src="/assets/svg/newspaperWhite.svg" alt="newspaper icon">
                        <p>Artigos</p>
                    </button>
            </div>
        </div>

        <app-carousel-simple title="Recomendado" colorTitle="white" [carouselData]="itemsRecommended">
        </app-carousel-simple>
       <app-carousel-simple title="Vídeos" colorTitle="white" [carouselData]="videoList">
        </app-carousel-simple>
        <app-carousel-simple title="Artigos" colorTitle="white" [carouselData]="articleList">
        </app-carousel-simple>
        <!--  <app-carousel-simple title="Comentários Pós-jogo" colorTitle="white" [carouselData]="itemsRecommended">
        </app-carousel-simple>
        <app-carousel-simple title="Outros Campeonatos" colorTitle="white" [carouselData]="itemsRecommended">
        </app-carousel-simple> -->

    </div>
</div>