import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { Location } from '@angular/common';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [AppButtonComponent, ReactiveFormsModule, AppInputCustomComponent, AppInputComponent, RouterLink],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  returnUrl: string;
  error: string;

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Faça seu login';

  loginFormGroup = new FormGroup({
    CPFControl: new FormControl<string>('', Validators.required),
    passwordControl: new FormControl<string>('', Validators.required),
  });

  buttonOptions = {
    buttonText: 'Voltar',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3',
    borderRadius: '25px'
  };

  wannaBePartnerBtn = {
    buttonText: 'Quero ser sócio',
    borderRadius: '25px',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-sm px-4 py-2 btn-primary'
  };

  loginBtn = {
    buttonText: 'Entrar',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonSize: 'btn btn-sm px-5 py-2 btn-primary',
  };

  private _destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private location: Location,
    private alertService: AlertService,
    private notificationService: NotificationsService,
    private spinnerService: SpinnerService
  ) {

    // Não deixa acessar a tela de login se estiver com credencias, usuário tem que fazer logout
    const currentUserValue = this.userService.currentUserValue ? Object.keys(this.userService.currentUserValue) : null; 
    if (currentUserValue?.length) {
      this.router.navigate(['/dashboard']);
    } else {
      this.spinnerService.hide();
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  back() {
    this.location.back();
  }

  login() {
    const userCredentials = {
      username: this.loginFormGroup.get('CPFControl')?.value as string,
      password: this.loginFormGroup.get('passwordControl')?.value as string
    };
    this.authService.login(userCredentials.username, userCredentials.password, this.returnUrl)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: () => {
        // this.notificationService.showSuccess('Login efetuado com sucesso!');
      },
      error: error => {
        if(error.error.code === 'ME037'){
          this.router.navigate(['/login/reenviar-email-confirmacao'], { state: { cpf: userCredentials.username } });
        }else{
          this.alertService.showAlert(AlertType.DANGER, error.error.message);
        }
        // this.notificationService.showError('Usuário ou senha inválidos!');
      }
    });
  }
  
  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }
}
