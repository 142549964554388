<div class="container p-4 p-md-5">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="partner-data-main-title my-4" [style.color]="labelColor">
        Dados de Sócio
    </div>
    <form [formGroup]="partnerDataformGroup">
        <div class="partner-data-snd-title mt-4 mb-2" [style.color]="labelColor">
            Dados Pessoais
        </div>
        <div class="row">
            <app-input class="col-md-6"
                [id]="'fullNameInputId'" [label]="'Nome Completo'" formControlName="name"></app-input>
            <app-input-custom class="col-md-3" [id]="'CPFInputId'" [label]="'CPF'" [mask]="InputMaskTypesEnum.CPF" formControlName="cpfControl"></app-input-custom>
            <app-input-custom class="col-md-3" [id]="'dateBirthInputId'" [label]="'Data de Nascimento'" [mask]="InputMaskTypesEnum.DATA"
            [placeholder]="'Informe seu nascimento'" formControlName="birthDate"
              [dropSpecialCharacters]="false">
            </app-input-custom> 
            <!-- <app-input class="col-md-3" [id]="'dateBirthInputId'" [label]="'Data de Nascimento'" [type]="InputTypeEnum.DATE" formControlName="birthDate"></app-input> -->
        </div>
        <div class="row">
            <app-select-input class="col-md-3"
                [id]="'genderInputId'"
                [label]="'Sexo'"
                [options]="genderOptions"
                formControlName="genderControl"
            ></app-select-input>
            <app-input class="col-md-6"
                [id]="'emailInputId'" [label]="'Email'" [type]="InputTypeEnum.EMAIL" formControlName="emailControl"></app-input>
            <app-input-custom class="col-md-3"
                [id]="'phoneInputId'"
                [label]="'Celular'"
                [placeholder]="'Informe o Celular'"
                [mask]="InputMaskTypesEnum.PHONE"
                formControlName="phoneControl"
            ></app-input-custom>
        </div>

        <div class="partner-data-snd-title mt-4 mb-2" [style.color]="labelColor">
            Endereço
        </div>
        <div class="row"> 
                <app-input-custom class="col-md-3"
                [id]="'CEPInputId'"
                [label]="'CEP'"
                [mask]="InputMaskTypesEnum.CEP"
                formControlName="CEPControl"
                (input)="getAddressByCEP()"></app-input-custom>
            <app-input class="col-md-6"
                [id]="'addressInputId'" [label]="'Endereço'" formControlName="addressControl"></app-input>
            <app-input class="col-md-3"
                [id]="'numberInputId'"
                [label]="'Número'"
                [placeholder]="'Informe o Número'"
                [type]="InputTypeEnum.NUMBER"
                formControlName="numberControl"
            ></app-input>
        </div>
        <div class="row">
            <app-input class="col-md-4"
                [id]="'neighborhoodInputId'" [label]="'Setor/Bairro'" [placeholder]="'Insira o bairro'" formControlName="neighborhoodControl"></app-input>
            <app-select-input class="col-md-4"
                [id]="'stateInputId'"
                [label]="'Estado'"
                [options]="stateOptions"
                formControlName="stateControl"
                (ngModelChange)="getCities()"
            ></app-select-input>
            <app-select-input class="col-md-4"
                [id]="'cityInputId'"
                [label]="'Cidade'"
                [options]="cityOptions"
                formControlName="cityControl"
            ></app-select-input>
        </div>
        <div class="row my-3">
            <app-checkbox class="col"
                [id]="'rankingConfirmarionId'"
                [label]="'Desejo participar dos rankings de sócios'"
                formControlName="rankingConfirmarionControl"
            ></app-checkbox>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-end my-3">
            <app-button
            id="submitBtn"
            [disabled]="partnerDataformGroup.invalid"
                [buttonOptions]="submitBtn"
                (buttonClick)="onSubmit()">
            </app-button>
        </div>
    </form>
</div>
