<div class="p-md-5 d-grid" id="view-invoice-page" *ngIf="invoice">
    <div class="pb-4 d-flex justify-content-between">
        <div class="view-invoice-title my-3">
            Visualizar fatura Nº {{invoice?.id | slice : 0 : 8 }}
        </div>
        <!--  Visão desktop: Mostra do md pra cima -->
        <div class="d-none d-md-grid align-content-center">
            <app-button id="back-desktop"
                [isIcon]="true" [left]="true"
                [iconName]="'bi bi-arrow-left'"
                [buttonOptions]="btnBackOptions"
                (buttonClick)="back()">
            </app-button>
        </div>
    </div>

    <div class="d-grid justify-content-center card-detail-invoice">
        <div class="card">
            <div class="card-body d-grid invoice-detail">
                <div class="d-flex justify-content-between">
                    <div class="d-grid">
                        <div class="card-title mb-4">Fatura Nº {{invoice?.id | slice : 0 : 8 }}</div>

                        <div class="my-1">Status: <i *ngIf="invoice?.invoicePaymentStatus?.statusTypeName !== 'Pago'" class="bi bi-info-circle-fill mx-1"></i><span class="description">{{invoice?.invoicePaymentStatus?.statusTypeName}}</span></div>
                        <div class="my-1">Referente: {{invoice.refMonth ? invoice?.refMonth + '/' + invoice?.refYear : '-'}}</div>
                        <div class="my-1">Vencimento: <span class="description">{{invoice.dueDate | date:'dd/MM/yyyy' : '+0000'}}</span></div>
                        <div class="my-1">Tipo: {{invoice?.invoiceType?.name}}</div>
                        <br/>
                        <div class="amount-title mt-1">Valor da fatura
                            <div class="py-2"></div>
                            <div class="invoice-amount">{{invoice.amount | customCurrency}}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="payment-history mt-5 px-3" *ngIf="invoice?.invoiceType?.id === '51c20cb8-871b-462d-8d23-31865105e298' || invoice?.invoiceType?.id === 'c2a91257-34ba-40ca-a168-52f3d8ec5b04'">
              <div class="payment-history-title text-left py-3" *ngIf="invoice?.invoiceType?.id === '51c20cb8-871b-462d-8d23-31865105e298'">Lista de Faturas Antecipadas</div>
              <div class="payment-history-title text-left py-3" *ngIf="invoice?.invoiceType?.id === 'c2a91257-34ba-40ca-a168-52f3d8ec5b04'">Lista de Faturas Renegociadas</div>
              <div class="history pt-4">
                  <div class="history-types">
                      <div class="table">
                          <table class="table table-striped">
                              <thead>
                                  <tr class="text-center">
                                      <th scope="col">Código</th>
                                      <th scope="col">Tipo de Fatura</th>
                                      <th scope="col">Referência</th>
                                      <th scope="col">Status</th>
                                  </tr>
                              </thead>
                              <tbody *ngFor="let i of faturasVinculadasList">
                                  <tr class="text-center">
                                      <td>{{i?.id | slice : 0 : 8 }}</td>
                                      <td>{{i?.invoiceType?.name}}</td>
                                      <td>{{i?.refMonth + '/' + i?.refYear}}</td>
                                      <td>{{i?.invoicePaymentStatus?.statusTypeName}}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>

            <div class="payment-history px-3">
                <div class="payment-history-title text-left py-3">Histórico de Tentativas de Pagamentos</div>
                <div class="history pt-4">
                    <div class="history-types">
                        <div class="table">
                            <table class="table table-striped">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col">Código</th>
                                        <th scope="col">Forma</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let op of invoice?.invoiceOperations">
                                    <tr class="text-center">
                                        <td>{{op?.id | slice : 0 : 8 }}</td>
                                        <td>{{op?.paymentMethod?.name}}</td>
                                        <td>{{op?.updatedAt | date:'dd/MM/yyyy HH:mm' : '+0000' }}</td>
                                        <td>{{this.getStatus(op?.status)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Visão mobile: Escondido do md pra cima -->
    <div class="d-grid d-md-none my-3 justify-content-end">
        <app-button id="back-mobile"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="btnBackOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
</div>

<app-payment-invoice-modal
    [modalId]="modalId"
    [paymentType]="paymentType"
    [paymentInvoiceModalData]="paymentInvoiceModalData"
    (confirmActionChange)="onModalConfirm()"
></app-payment-invoice-modal>
