<div class="modal fade align-content-center notification-modal" id="{{modalId()}}" data-bs-keyboard="false"
  tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    @if (notificationModalData()) {
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">{{notificationModalData().title}}</h1>
      </div>
      <div class="modal-body">
        {{notificationModalData().subject}}
      </div>

      <div class="modal-footer">
        <app-button class="mx-2 my-1" id="confirmModalBtn" [buttonOptions]="confirmBtn"
          (buttonClick)="onConfirm()"></app-button>
      </div>

    </div>
  }
  </div>
</div>
