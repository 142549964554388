import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, input, model } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from '../app-button/app-button.component';
import { AppSelectInputComponent, SelectModel } from '../app-select-input/app-select-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CNPJPipe } from '../../pipe/cnpj.pipe';
import { CustomButtonDto } from '../app-button/app-button-dto';
import { WaveService } from 'src/app/core/services/wave.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

declare var bootstrap: any;

@Component({
  selector: 'app-form-modal',
  standalone: true,
  imports: [AppButtonComponent, AppSelectInputComponent, ReactiveFormsModule],
  templateUrl: './app-form-modal.component.html',
  styleUrl: './app-form-modal.component.scss'
})
export class AppFormModalComponent implements OnInit, OnDestroy {
  modalId = input.required<string>();
  modalTitle = input<string>('Modal de confirmação');
  modalBodyMessage = input<string>("");
  modalConfirmLabel = input<string>('');
  modalCancelLabel = input<string>('');
  showSelectInput = input<boolean>(true);
  showCloseBtn = input<boolean>(true);

  selectControl = input<FormControl>(new FormControl());
  ticketControl = input<FormControl>(new FormControl());
  isDependent = false;

  @Input() selectInputLabel: string;
  @Input() checkinSelected: any;
  @Input() selectInputOptions: SelectModel[];
  @Input() ticketData: any;
  @Input() checkinsHistory: any;

  ticketsOptions: any[] = [];
  modalInstance: any; // Declaração da variável modalInstance para armazenar a instância do modal

  @Output() onModalConfirm = new EventEmitter();

  confirmAction = model<boolean | FormControl>(false);

  private modalSubscription: Subscription | undefined;

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
  };

  confirmBtn = {
    buttonText: 'Confirmar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  visualizarOptions = {
    buttonText: 'Visualizar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 btn-primary',
  } as CustomButtonDto;

  cancelarOptions = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 btn-primary',
  } as CustomButtonDto;

  constructor(private modalService: ModalService,
    private waveService: WaveService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      const modalElement = document.getElementById(state.id);
      if (modalElement) {
        if (state.action === 'open') {
          this.modalInstance = new bootstrap.Modal(modalElement); // Instanciando o modal
          this.modalInstance.show();
          modalElement.addEventListener('hidden.bs.modal', () => {
            this.resetModal();
          });
          console.log(this.selectInputOptions)
          if(this.selectInputOptions.length === 1) {
            setTimeout(() => {
              this.selectControl().setValue(this.selectInputOptions[0].code);
              this.alterarTickets()
            }, 500);
          }
        } else if (state.action === 'close') {
          if (this.modalInstance) {
            this.modalInstance.hide();
          }
        }
      }
    });

    if (this.modalId() === 'modalCheckinTicket') {
      // Logica adicional se necessário
    }

    this.setBtnLabel();
    this.ticketControl().disable();
  }



  closeModal() {
    if (this.modalInstance) {
      this.modalInstance.hide();
    }
    this.resetModal(); // Chama a função de resetar o modal
  }

  resetModal() {
    this.selectControl().reset();
    this.ticketControl().reset();
    this.ticketControl().disable();
    this.ticketsOptions = [];
    this.isDependent = false;
  }

  setBtnLabel(): void {
    if (this.modalCancelLabel() !== '')
      this.cancelBtn.buttonText = this.modalCancelLabel();
    if (this.modalConfirmLabel() !== '')
      this.confirmBtn.buttonText = this.modalConfirmLabel();
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  cancelarCheckin(checkin: any){
    this.waveService.cancelByOrderId(checkin.orderId).subscribe({
      next : (res: any) => {
        this.waveService.cancelCheckin(checkin.id).subscribe(res => {
          this.closeModal();
          this.alertService.showAlert(AlertType.SUCCESS, 'Checkin cancelado com sucesso.');
        })
      }
    })
  }

  visualizarCheckin(link : any){
    window.open(link, '_blank');
  }

  getTicketById(checkin: any){
    const waveSelect = this.ticketData.find((i: any) => i.id === this.checkinSelected);
    if(checkin.memberDependentName){
      return waveSelect.waveTicketsDependent.find((i: any) => i.id === checkin.ticket).ticketName
    } else {
      return waveSelect.waveTicketsSocio.find((i: any) => i.id === checkin.ticket).ticketName
    }
  }

  alterarTickets() {
    const waveSelect = this.ticketData.find((i: any) => i.id === this.checkinSelected);
    let clientSelect = this.selectInputOptions.find(i => i.code === this.selectControl()?.value);
    if (clientSelect) {
      this.ticketControl().enable();
    }
    if (clientSelect?.name.includes('Sócio') && waveSelect?.waveTicketsSocio.length > 0) {
      this.isDependent = false;
      this.ticketsOptions = [];
      waveSelect.waveTicketsSocio.forEach((t: any) => {
        let ti: SelectModel = {
          code: t.ticketId,
          name: t.ticketName
        }
        this.ticketsOptions.push(ti);
      });
    } else if(clientSelect?.name.includes('Dependente') && waveSelect?.waveTicketsDependent.length > 0) {
      this.isDependent = true;
      this.ticketsOptions = [];
      waveSelect.waveTicketsDependent.forEach((t: any) => {
        let td: SelectModel = {
          code: t.ticketId,
          name: t.ticketName
        }
        this.ticketsOptions.push(td);
      });
    }
  }

  onConfirm() {
    const dataConfirm: any = {
      clientSelected: this.selectControl().value,
      ticketSelected: this.ticketControl().value,
      isDependent: this.isDependent
    }
    this.onModalConfirm.emit(dataConfirm);
    this.modalInstance.hide();
  }
}
