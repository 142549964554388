const dns = '__API_GATEWAY__';
const dnsPublic = '__API_GATEWAY__';
const authApiUrl = `${dns}/api/v1/auth`;
const socioApiUrl = `${dns}/api/v1/socio`;
const publicApiAuthUrl = `${dnsPublic}/public/api/`;
const publicApiSocioUrl = `${dnsPublic}/public/api/`;
const publicApiAdminUrl = `${dnsPublic}/public/api/`;
const urlS3 = 'https://efui-dev-api-docs.s3.sa-east-1.amazonaws.com';



export const environment = {
    production: true,
    dns: dnsPublic,
    xTenantId: '__TENANT_ID__',
    authApiUrl: authApiUrl,
    socioApiUrl: socioApiUrl,
    publicApiSocioUrl: publicApiSocioUrl,
    publicApiAdminUrl: publicApiAdminUrl,
    publicApiAuthUrl: publicApiAuthUrl,
    urlS3: urlS3
};

