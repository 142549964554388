import { CommonModule } from '@angular/common';
import { Component, OnInit, input } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CardsResgatar } from '../../models/cardsResgatar';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-cards-resgatar',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink],
  templateUrl: './app-cards-resgatar.component.html',
  styleUrl: './app-cards-resgatar.component.scss'
})
export class AppCardsResgatarComponent implements OnInit {
  labelColor: '#FFFFFF';
  data = input.required<CardsResgatar[]>();
  title = input<string>();
  typeContent = input<string>();
  showItemResgatado = false;

  checkInBtn = {
    buttonText: "Resgatar",
    borderRadius: "25px",
    buttonSize: "btn btn-md py-2 btn-primary",
    fontSize: 14
  };

  buyBtn = {
    buttonText: "Comprar",
    borderRadius: "25px",
    buttonSize: "btn btn-md py-2 btn-secondary",
    fontSize: 14
  };

  resgatarItem() {
    this.showItemResgatado = true;
  }

  goTo(link:string) {
    console.log(link) 
    if(link !== '' && link !== null && link !== undefined) {
      window.open(link, '_blank');
    }
  }

  ngOnInit(): void {}

  logdata() {
    console.log(this.data());
  }
  
}
