import { Component, ElementRef, EventEmitter, input, OnInit, Output, ViewChild } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { PlanDashboardTO } from '../../models/planModel';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ViewPlanCardComponent } from '../view-plan-card/view-plan-card.component';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from '../../models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { CustomCurrencyPipe } from "../../pipe/custom-currency.pipe";

@Component({
  selector: 'app-my-plan-card',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink, ViewPlanCardComponent, CustomCurrencyPipe],
  templateUrl: './my-plan-card.component.html',
  styleUrls: ['./my-plan-card.component.scss']
})
export class MyPlanCardComponent implements OnInit {
  plan = input.required<PlanDashboardTO>();
  //memberShipCard = input<any>();
  memberShipCard: any;
  detailedMode = input<boolean>(false);
  showDowngrade = input<boolean>(false);
  @Output() heightEvent = new EventEmitter<void>();
  @ViewChild('planCard') planCard!: ElementRef;

  public styles: { [key: string]: string };

  carteirinhaImg = localStorage.getItem('imageLogo');
  public trigger$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  docsOptions = {
    buttonText: 'Enviar documentação',
    buttonSize: 'btn btn-md px-3 py-1 float-right my-2 btn-primary',
    borderRadius: '25px'
  };

  btnPlanDetailsOptions = {
    buttonText: 'Ver detalhes do plano',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEditPlanOptions = {
    buttonText: 'Editar Plano',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEnvDocumentsOptions = {
    buttonText: 'Enviar Documentos',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };
  userInfo: UserModel;
  currentPlan!: any;

  constructor(
    private memberService: MemberService,
    private userService: UserService,
  ){}

  ngOnInit(): void {
    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };

    console.log(this.plan().member.id)
    this.memberService.getMemberShipCardDashboardData(this.plan().member.id).subscribe(res =>{
      this.memberShipCard = res;
    })

    this.userService.currentUser.subscribe(user => {
      this.userInfo = user as UserModel;
      this.memberService.getCurrentPlan(this.userInfo.id).subscribe((currentPlan:any)=>{
        this.currentPlan = currentPlan;
      })
    });

  }

  ngAfterViewInit(): void {
    // Certifica-se que o DOM foi carregado
    setTimeout(() => {
      const planCardHeight = this.planCard.nativeElement.offsetHeight;
      console.log(planCardHeight)
      this.heightEvent.emit(planCardHeight+2);
    }, 800);
  }

  setHeightCard(height:any){
    const planCard = document.getElementById('planCard');
      if (planCard) {
        planCard.style.height = `${height}px`; 
      }
  }

  showCarteirinha() {
    this.trigger$.next(true);
  }
}
