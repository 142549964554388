import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-convidar-amigo',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, ReactiveFormsModule],
  templateUrl: './convidar-amigo.component.html',
  styleUrl: './convidar-amigo.component.scss'
})
export class ConvidarAmigoComponent implements OnInit{
  typeActive = 'email';
  filterGroup = new FormGroup({
    filterControl: new FormControl<string>('', Validators.required)
  });
  convitesEnviados: any;

  backBtn = {
    buttonText: 'Voltar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-secondary',
  };

  sendBtn = {
    buttonText: 'Enviar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
  
  ngOnInit(): void {
    this.convitesEnviados = [
      {
        position: 1,
        email: 'diones.santos@gmail.com',
        status: 'Aceito',
      },
      {
        position: 2,
        email: 'guilherme.oliveira@gmail.com',
        status: 'Enviado',
      },
      {
        position: 3,
        email: 'josias.silva@gmail.com',
        status: 'Enviado',
      },
      {
        position: 4,
        email: 'luan.santana@gmail.com',
        status: 'Aceito',
      }
    ];
  }
  
}
