@if (styles()) {
<div class="user-area d-flex" *ngIf="userInfo()" [ngStyle]="styles()">

  <!--  Visão desktop: Mostra do md pra cima -->
  <div class="d-none d-md-grid align-content-end ps-2">
    <div class="mx-2 user-name" [appTruncateText]="20">
      {{userInfo().name}}
    </div>
    <div class="mx-2 user-score">
      Rank: {{userInfoMock.rank}} <i class="bi bi-dot"></i>
      <span>{{userInfoMock.score}} pontos</span>
    </div>
  </div>

  
  <div class="dropdown">
    <!--  Visão desktop: Mostra do md pra cima -->
    <div class="d-none d-md-block user-img me-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <img [src]="userInfoMock.photo" alt="User image">
    </div>

    <app-user-actions class="dropdown-menu" [userBlocked]="userBlocked()"></app-user-actions>

  </div>
</div>
}