@if (carouselData() && carouselData().length > 0) {
    <div class="carousel-config">
        <h3 class="main-title" [style.color]="colorTitle()">{{ title() }}</h3>
        <owl-carousel-o [options]="customOptions" class="carousel" [class.w-auto]='carouselData().length < 3'>
            @for (item of carouselData(); track $index) {
                <ng-container>
                    <ng-template carouselSlide id="$index" >
                        <a href="#" (click)="handleClick(item, $event)">
                            <img class="banner" [src]="item.image.url" [alt]="item.image.alt" [title]="item.image.title" *ngIf="item.image.url !== ''">
                            <div class="withoutImage" *ngIf="item.image.url == ''"></div>
                            <div class="icons">
                                @if(item.type == 'video') {
                                    <img src="/assets/svg/film.svg" alt="film icon">
                                }
                                @if(item.type == 'image') {
                                    <img src="/assets/svg/newspaper.svg" alt="newspaper icon">
                                }
                                
                            </div>
                            @if(item.subtitle !== '') {
                                <div class="subtitle">
                                    <p>{{ item.subtitle }}</p>
                                </div>
                            }
                            
                        </a>
                    </ng-template>
                </ng-container>
            }
        </owl-carousel-o>
    </div>
}
    

