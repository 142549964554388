import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { InfoCardComponent } from 'src/app/shared/components/info-card/info-card.component';
import { RankingTableComponent } from 'src/app/shared/components/ranking-table/ranking-table.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';
import { Location } from '@angular/common';

interface Row {
  ranking: number;
  photo: string;
  socio: string;
  cidade: string;
  pontos: number;
  trend?: 'up' | 'down';
}

@Component({
  selector: 'app-ranking-de-socios',
  standalone: true,
  imports: [
    CommonModule,
    InfoCardComponent,
    AppButtonComponent,
    RankingTableComponent,
    RouterLink
  ],
  templateUrl: './ranking-de-socios.component.html',
  styleUrl: './ranking-de-socios.component.scss'
})
export class RankingDeSociosComponent implements OnInit {
  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };
  
  infos = [
    { title: 'Posição atual', value: '3º' },
    { title: 'Pontos', value: '475' },
    { title: 'Tendência', value: '', icon: 'bi-caret-down-fill', extraText: 'Descendo' },
    { title: 'Tempo no rank atual', value: '92 dias' }
  ];
  public styles: { [key: string]: any };
  labelColor = 'white';
  constructor( private layoutConfigService: LayoutConfigService, private location: Location ) {}

  rows: Row[] = [
    { ranking: 1, photo: 'assets/imagens/socios/socio-1.png', socio: 'Diones Santos', cidade: 'Brasília - DF', pontos: 500, trend: 'up' },
    { ranking: 2, photo: 'assets/imagens/socios/socio.png', socio: 'Guilherme de Oliveira', cidade: 'Brasília - DF', pontos: 490, trend: 'up' },
    { ranking: 3, photo: 'assets/imagens/socios/socio-2.png', socio: 'Josias Silva', cidade: 'Goiânia - CO', pontos: 475, trend: 'down' },
    { ranking: 4, photo: 'assets/imagens/socios/socio.png', socio: 'Fellipe Amorim', cidade: 'Belo Horizonte - MG', pontos: 470, trend: 'up' },
    { ranking: 5, photo: 'assets/imagens/socios/socio-3.png', socio: 'Joao Gutierre', cidade: 'São Paulo - SP', pontos: 450, trend: 'down' },
    { ranking: 6, photo: 'assets/imagens/socios/socio-4.png', socio: 'Fabrício Fernandes', cidade: 'Rio de Janeiro - RJ', pontos: 450, trend: 'up' },
    { ranking: 7, photo: 'assets/imagens/socios/socio-5.png', socio: 'Fábio Junior', cidade: 'Vitória - ES', pontos: 430, trend: 'down' },
    { ranking: 8, photo: 'assets/imagens/socios/socio.png', socio: 'Luan Santana', cidade: 'Fortaleza - CE', pontos: 420, trend: 'up' },
    { ranking: 9, photo: 'assets/imagens/socios/socio-6.png', socio: 'Moises Moura', cidade: 'Belo Horizonte - MG', pontos: 420, trend: 'up' },
    { ranking: 10, photo: 'assets/imagens/socios/socio-7.png', socio: 'Pedro Reis', cidade: 'Goiânia - CO', pontos: 390, trend: 'down' },
  ];

  public buttonOptions: any;
  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.buttonOptions = {
      buttonText: 'Visualizar histórico completo',
      buttonSize: 'btn btn-sm btn-primary',
      borderRadius: layoutVersion === 1 ? '25px' : '0px'
    };
    this.styles = {
      '--border-radius': layoutVersion === 1 ? '15px' : '0px'
    };
  }

  back() {
    this.location.back();
  }

}
