import { CommonModule } from '@angular/common';
import { Component, OnInit  } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';

@Component({
  selector: 'app-historico-de-pontos',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    RouterLink
  ],
  templateUrl: './historico-de-pontos.component.html',
  styleUrl: './historico-de-pontos.component.scss'
})
export class HistoricoDePontosComponent implements OnInit {
  totalPontos: number = 475;
  public buttonOptions: any;
  historicoPontos = [
    {
      data: new Date('2024-03-08'),
      itens: [
        { descricao: 'Renovação anual de plano', pontos: 300 },
        { descricao: 'Experiência "Camiseta exclusiva" resgatado', pontos: -250 },
        { descricao: 'Amigo convidado', pontos: 100 }
      ]
    },
    {
      data: new Date('2023-10-09'),
      itens: [
        { descricao: 'Renovação anual de plano', pontos: 300 },
        { descricao: 'Experiência "Camiseta exclusiva" resgatado', pontos: -250 },
        { descricao: 'Amigo convidado', pontos: 100 }
      ]
    }
  ];

  constructor(private layoutConfigService: LayoutConfigService) { }

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.buttonOptions = {
      buttonText: 'Voltar',
      buttonSize: 'btn btn-md btn-secondary',
      borderRadius: layoutVersion === 1 ? '25px' : '0px',
      fontSize: 14
    };
  }
}
