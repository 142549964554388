import { Component, Input, OnInit, WritableSignal, computed, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ModalService } from 'src/app/core/services/modal.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CustomCurrencyPipe } from '../../pipe/custom-currency.pipe';
import { PaymentModalData } from '../payment-modal/payment-modal.component';
import { InvoiceModel, InvoiceModelPublic, StatusInvoice } from '../../models/invoiceModel';
import { CreditCardInfoModel, PaymentType } from '../../models/paymentData';
import { SelectModel } from '../app-select-input/app-select-input.component';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { PaymentInvoiceModalComponent, PaymentInvoiceModalData } from '../payment-invoice-modal/payment-invoice-modal.component';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-invoice-table',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    MatIconModule,
    CustomCurrencyPipe,
    PaymentInvoiceModalComponent
  ],
  templateUrl: './invoice-table.component.html',
  styleUrl: './invoice-table.component.scss'
})
export class InvoiceTableComponent implements OnInit {
  invoiceList = input.required<InvoiceModelPublic[]>();

  @Input() memberPlanId : any;

  pageSize: number = 1000;
  totalPages: number = 0;

  currentPage: WritableSignal<number> = signal(1);
  invoicesPerPage = computed<InvoiceModelPublic[]>(() => {
    if (!this.invoiceList()?.length) {
      return [];
    }

    const startIndex = (this.currentPage() - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    return this.invoiceList().slice(startIndex, endIndex);
  });

  fullMode = input<boolean>(false);

  StatusInvoice = StatusInvoice;

  paymentType = PaymentType.CREDITO;
  modalId = 'payment-modal-table';
  PaymentType = PaymentType;
  paymentInvoiceModalData: PaymentInvoiceModalData;
  private fragment: string | null = null;

  btnManageInvoicesOptions = {
    buttonText: 'Gerenciar Faturas',
    borderRadius: '25px',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12
  };

  btnAntecipationInvoicesOptions = {
    buttonText: 'Antecipar Faturas',
    borderRadius: '25px',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-sm fs-xsmall btn-secondary',
    fontSize: 12
  };

  styles = {
    '--primary-color': localStorage.getItem('primary-color'),
    '--secondary-color': localStorage.getItem('secondary-color'),
  }

  creditCardInfo: WritableSignal<CreditCardInfoModel[]> = signal([]);

  creditCardOptions = computed<SelectModel[]>(() => {
    if (!this.creditCardInfo()) {
      return [];
    }

    return this.creditCardInfo().map(cardInfo => {
      return {
        code: cardInfo.cardNumber, // Use card number as code
        name: `${cardInfo.cardOperator} - ....${cardInfo.cardNumber.slice(-4)}`,
      } as SelectModel;
    });
  });

  invoiceTypes: SelectModel[] = [];
  showTable = false;

  constructor(private modalService: ModalService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private alertService : AlertService,
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
    const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
    this.creditCardInfo.set(creditCardArr);
    this.totalPages = Math.ceil(this.invoiceList?.length / this.pageSize);
    
    // this.updatePagedItems();
  }

  openModal(paymentType: PaymentType, invoice: InvoiceModelPublic): void {
    this.setModalData(paymentType, invoice);    
    console.log('invoicesPerPage', this.invoicesPerPage(), invoice);
    this.modalService.open(this.modalId);
  }

  setModalData(paymentType: PaymentType, invoice: InvoiceModelPublic): void {
    this.paymentType = paymentType;

    switch (paymentType) {
      case PaymentType.PIX:
        this.paymentInvoiceModalData = {
          invoiceId: invoice.id,
          invoiceAmount: invoice.amount,
          invoiceNumber: invoice.id?.split('-')[0],
        };
        break;
      case PaymentType.CREDITO:
        this.paymentInvoiceModalData = {
          invoiceId: invoice.id,
          invoiceAmount: invoice.amount,
          invoiceNumber: invoice.id?.split('-')[0],
          creditCardOptions: this.creditCardOptions()
        };
        break;

      default:
        break;
    }
  }

  onModalConfirm(): void {
    this.modalService.close(this.modalId);
    console.log('TODO: Ação de confirmação do modal');
  }

  goToInvoiceDetail(invoice: any): void {
    // TODO: Mudar approach se o invoice obj for muito grande
    this.router.navigate(['/ver-fatura'], { state: { invoice: invoice } });
  }

  // updatePagedItems() {
  //   const startIndex = (this.currentPage() - 1) * this.pageSize;
  //   const endIndex = startIndex + this.pageSize;
  //   this.invoicesPerPage = this.invoiceList().slice(startIndex, endIndex);
  // }

  nextPage() {
    if (this.currentPage() < this.totalPages) {
      this.currentPage.set(this.currentPage() + 1);
      // this.updatePagedItems();
    }
  }

  prevPage() {
    if (this.currentPage() > 1) {
      // this.currentPage()--;
      this.currentPage.set(this.currentPage() - 1);
      // this.updatePagedItems();
    }
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage.set(page);
      // this.updatePagedItems();
    }
  }

  goToUrl() {
    this.router.navigate(['/meu-plano'], { fragment: 'invoice-card' });
  }
 
  goToAntecipation() {
    this.invoiceService.getNextInvoices(this.memberPlanId).subscribe(res => {
      if(res.length === 0) {
        this.alertService.showAlert(AlertType.WARNING, 'Não existem faturas para serem antecipadas.');
      } else {
        this.router.navigate(['/antecipar-fatura'], { state: { memberPlanId: this.memberPlanId } });
      }
    })
      
  }

  ngAfterViewInit(): void {
    // Rolar para o elemento após a visualização do componente
    if (this.fragment) {
      const element = document.getElementById(this.fragment);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

}
