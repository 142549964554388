import { Component, input, OnInit } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { Router } from '@angular/router';
import { PlanUpgrade } from '../../models/planModel';

@Component({
  selector: 'app-upgrade-card',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './upgrade-card.component.html',
  styleUrl: './upgrade-card.component.scss'
})
export class UpgradeCardComponent implements OnInit {

  gamerImg = '/assets/imagens/gamer-standup.png';
  router: Router;
  constructor(router: Router) {
    this.router = router;
  }

  ngOnInit(){
  }

  

  btnUpgradeOptions = {
    buttonText: 'Fazer Upgrade',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-small py-1 px-3 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  btnUpgradeClick() {
    this.router.navigate(['/upgrade-plano']);
  }




}
