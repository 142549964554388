import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { BenefitsService } from 'src/app/core/services/benefits.service';
import { CommonService } from 'src/app/core/services/common.service';
import { StyleContentSectionBenefit } from 'src/app/shared/models/styleContent';

@Component({
  selector: 'app-section-card-beneficios',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './section-card-beneficios.component.html',
  styleUrl: './section-card-beneficios.component.scss'
})
export class SectionCardBeneficiosComponent {

  url: string

  isMobile = window.innerWidth < 768;
  public styles: { [key: string]: any };


  constructor(
    private benefitsService: BenefitsService,
    private commonService: CommonService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
  }

  ngOnInit(): void {
    this.getBannerConfig();
  }

  getBannerConfig() {
    this.benefitsService.getBannerConfig().subscribe((response: StyleContentSectionBenefit) => {
      let benefitSecResp = response;
      this.url = this.commonService.getImageStr(benefitSecResp.banner.id)
      this.setStyles(benefitSecResp);
    });
  }

  setStyles(styles: StyleContentSectionBenefit) {
    this.styles = {
      '--show-section' : styles.showSection ? 'block' : 'none',
      '--background-image': styles.backgroundIsImage ?  `url(${this.commonService.getImageStr(styles.document?.id)})` : 'none',
      '--background-color' : styles.backgroundColor
    };    
  }

}
