import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, computed, input } from '@angular/core';
import { Observable, Subject, concatMap, filter, from, interval, map, switchMap, takeUntil } from 'rxjs';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { LogoTextComponent } from '../logo-text/logo-text.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { UserModel } from 'src/app/shared/models/userModel';
import { NotificationDropdownComponent } from '../../../features/notifications/notification-dropdown/notification-dropdown.component';
import { UserSideBarComponent } from './user-side-bar/user-side-bar.component';
import { NotificationsService } from '../../services/notifications.service';
import { Notification } from 'src/app/shared/models/notificationsModel';
import { AlertService, AlertType } from '../../services/alert.service';
import { HeaderService } from '../../services/header.service';
import { FileService } from '../../services/file.service';
import { CustomButtonDto } from 'src/app/shared/components/app-button/app-button-dto';
import { UserService } from '../../services/user.service';
import { StyleContentHeader } from 'src/app/shared/models/styleContent';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule,
    AppButtonComponent,
    LogoTextComponent,
    RouterLink,
    UserDropdownComponent,
    NotificationDropdownComponent,
    UserSideBarComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isLoggedIn = input<boolean>();

  userInfo: UserModel;

  get hasUserInfo() {
    if(!this.userInfo) {
      return 0;
    }

    return Object.keys(this.userInfo)?.length;
  }

  headerItems = computed<any[]>(() => {
    this.selectedMenuItem = '';
    if (this.isLoggedIn()) {
      return [
        { name: 'INGRESSOS', id: 'ingressos' },
        { name: 'EXPERIÊNCIAS', id: 'experiencias' },
        { name: 'PARCEIROS', id: 'parceiros' },
        { name: 'TRANSPARÊNCIA', id: 'transparencia' },
        { name: 'RANKING', id: 'ranking' },
        { name: 'EXCLUSIVO', id: 'exclusivo' }
      ];
    } else {
      return [
        { name: 'PLANOS', id: 'planos' },
        { name: 'INGRESSOS', id: 'ingressos' },
        { name: 'EXCLUSIVO', id: 'exclusivo' },
        { name: 'BENEFÍCIOS', id: 'beneficios' },
        { name: 'RANKING', id: 'ranking' }
      ];
    }
  });

  notifications: Notification[];

  logoUrl: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  buttonStyles: { [key: string]: string } = {};
  menuStyles: { [key: string]: string } = {};
  importantStyles: any = null;
  logoStyles: any = null;

  private _destroy$ = new Subject<void>();

  buttonGonnaBePartnerOptions: CustomButtonDto;

  buttonLoginOptions: CustomButtonDto;
  setFromHeader = false;

  selectedMenuItem = '';
  currentUrl = '';
  isBlocked = localStorage.getItem('enablePortal') === 'false';
  userBlocked = false;

  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private alertService: AlertService,
    private headerService: HeaderService,
    private fileService: FileService,
    private userService: UserService,
    private commonService: CommonService
  ) {
    // Carrega as notificações ao entrar na aplicação
    this.userService.currentUser.subscribe(user => {
      this.userInfo = user as UserModel;
      if (this.userInfo) {
        this.loadNotifications().subscribe({
          next: (data) => this.notifications = this.orderByUnread(data),
        });
      }
      this.userBlocked = !this.userInfo?.memberPlans?.length || this.userInfo?.memberSituation?.name == 'Inadimplente';      
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { // Check for NavigationEnd event
        this.currentUrl = event.urlAfterRedirects; // Access the full URL after redirects

        this.resetNavSelection(this.currentUrl);
      }
    });
  }

  ngOnInit(): void {
    this.setHeaderStyle();

    // Escuta mudanças na pagina de lista de notificações e atualiza o header de acordo
    this.notificationService.updateNotificationSubject
      .pipe(takeUntil(this._destroy$))
      .subscribe((resp: Notification[]) => {
        if (!this.setFromHeader) {
          this.notifications = resp;
        }
        this.setFromHeader = false;
      });

    // Update notification from time to time
    this.automaticallyUpdateNotifications();
  }

  private resetNavSelection(url: string, clickOnLogo = false): void {
    const onHeaderNavItems =
      url.includes('check-in') || url.includes('experiencias') ||
      url.includes('parcerias') || url.includes('transparencia') ||
      url.includes('ranking-de-socios') || url.includes('conteudo-exclusivo');

    if (!this.isLoggedIn() && url !== '/' || clickOnLogo) {
      this.selectedMenuItem = '';
    } else if (this.isLoggedIn() && !onHeaderNavItems) {
      this.selectedMenuItem = '';
    }
  }

  private automaticallyUpdateNotifications(): void {
    // Request every 5 minutes (300000 ms)
    interval(300000).pipe(
      switchMap(() => this.loadNotifications()),
      map(notifications => this.orderByUnread(notifications)),
      takeUntil(this._destroy$)
    ).subscribe({
      next: (notifications) => this.notifications = notifications,
    });
  }

  private loadNotifications(): Observable<Notification[]> {
    const cpf = this.userInfo?.cpf;
    const memberId = this.userInfo?.id;

    return this.notificationService.getAllNotifications(cpf, memberId);
  }

  private setHeaderStyle(): void {
    this.headerService.getHeader().subscribe({
      next: (res: StyleContentHeader) => {
        const styles = res;

        this.setElementsStyles(styles);
        this.setBtnStyles(styles);
      }
    });
  }

  setElementsStyles(styles: StyleContentHeader): void {
    if (!styles) {
      return;
    }

    this.menuStyles = {
      '--menu-font-size': styles.memberCountTextSize + 'px',
      '--menu-color-item': styles.menuItemColor,
      '--menu-color-item-hover': styles.menuItemHoverColor,
      '--menu-color-item-selected': styles.menuItemSelectedColor,
      '--menu-color-item-disabled': '#ccc',
      '--menu-background-color': styles.menuBackgroundColor,
      '--header-background-color': styles.menuBackgroundColor,
      '--button-color-hover' :styles.buttonColorWhenHovering,
      '--button-color-text-hover' :styles.buttonTextColorWhenHovering,
      '--button-color-text' :styles.buttonTextColor,
      '--button-border' :styles.buttonBorderColor,
      '--button-border-enter' :styles.buttonBorderColorEnter,
      '--button-color-text-enter' :styles.buttonTextColorEnter,
      '--button-color-hover-enter' :styles.buttonColorWhenHoveringEnter,
      '--button-color-text-hover-enter' :styles.buttonTextColorWhenHoveringEnter,
      '--button-color': styles.buttonColor,
      '--button-color-enter': styles.buttonColorEnter,
    };

    const prefixText = styles.memberNumberPhrases?.filter((phrase: any) => phrase.inUse)[0]?.phrase;
    const docDownloadUrl = this.fileService.getDocDownloadUrl(styles.imageLogo?.id)
    this.logoStyles = {
      url: docDownloadUrl || localStorage.getItem('imageLogo') || '',
      style: `color: ${styles.menuItemColor} !important; font-size: ${styles.memberCountTextSize}px !important;`,
      prefixText: prefixText,
      '--primaryColor': styles.buttonColor,
      '--secondaryColor': styles.secondaryColor,
      '--prefixColor': styles.memberPhrasesTextColor,
      '--highlightedColor': styles.highlightedColor,
      '--suffixColor': styles.suffixColor,
      '--prefixWeight': styles.prefixWeight,
      '--highlightedWeight': styles.highlightedWeight,
      '--suffixWeight': styles.suffixWeight,
      '--prefixSize': styles.prefixSize,
      '--highlightedSize': styles.highlightedSize,
      '--suffixSize': styles.suffixSize
    }
  }

  setBtnStyles(styles: StyleContentHeader): void {
    if (!styles) {
      return;
    }

    this.buttonGonnaBePartnerOptions = {
      primaryColor: styles.buttonColor,
      secondaryColor: styles.secondaryColor,
      buttonText: 'Quero ser sócio',
      buttonTextColor: styles.buttonTextColor,
      borderRadius: '25px',
      buttonBorderWidth: styles.buttonBorder ? `1px` : 'none',
      buttonBorderColor: styles.buttonBorder ? styles.buttonBorderColor : 'none',
      buttonSize: 'btn btn-sm px-4 py-2',
      fontSize: Number(styles.memberCountTextSize)
    };

    this.buttonLoginOptions = {
      primaryColor: styles.buttonColorEnter,
      secondaryColor: styles.secondaryColor,
      buttonText: 'Entrar',
      buttonTextColor: styles.buttonTextColorEnter,
      borderRadius: '25px',
      buttonBorderWidth: styles.buttonBorderEnter ? `1px` : 'none',
      buttonBorderColor: styles.buttonBorderEnter ? styles.buttonBorderColorEnter : 'none',
      buttonSize: 'btn btn-sm px-4 py-2',
      fontSize: Number(styles.memberCountTextSize)
    };
  }

  orderByUnread(notificationsList: Notification[]): Notification[] {
    return notificationsList.sort(function (x) {
      return (x.isViewed === true) ? 0 : x ? -1 : 1;
    });
  }

  markAllNotificationsAsRead() {
    const memberId = this.userInfo.id;

    // Using concatMap to process one request at a time in order
    from(this.notifications)
      .pipe(
        filter(notification => !notification.isViewed),
        concatMap(notification => this.notificationService.setNotificationViwed(notification.id, memberId))
      )
      .subscribe({
        complete: () => {
          this.notifications = this.setNotificationArrRead(this.notifications);

          //Atualizar lista de notificações
          this.updateNotificationList(this.notifications);

          this.alertService.showAlert(AlertType.SUCCESS, 'Todas notificações foram marcadas como lidas!');
        }
      });
  }

  setNotificationArrRead(notifications: Notification[]): Notification[] {
    if (!notifications.length) {
      return [];
    }

    return notifications.map(notification => {
      notification.isViewed = true;
      return notification;
    });
  }

  updateNotificationList(notifications: Notification[]) {
    if (this.currentUrl === '/lista-notificacoes') {
      this.setFromHeader = true;
      this.notificationService.setUpdateNotificationSubject(notifications);
    }
  }

  async navigationLogo() {
    this.resetNavSelection(this.currentUrl, true);

    if (this.isLoggedIn()) {
      this.goToDashboard();
    } else {
      await this.goToHome();

      if (this.currentUrl === '/') {
        this.scrollToSection('main');
      } else if (this.currentUrl.includes('login') || this.currentUrl === '/cadastro-socio' || this.currentUrl === '/faq') {
        this.scrollToSection('exclusivo');
      }
    }
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  async navigationHeaderItems(menuItem: string) {
    if (this.isLoggedIn() && this.userBlocked) {
      return;
    }

    this.selectedMenuItem = menuItem;

    if (this.isLoggedIn()) {
      this.headerLinksNavigation(menuItem);
    } else {
      await this.goToHome();
      this.scrollToSection(menuItem);
    }
  }

  async goToHome() {
    if (this.currentUrl !== '/') {
      this.router.navigate(['/']);
    }
  }

  private headerLinksNavigation(menuItem: string): void {
    switch (menuItem) {
      case 'ingressos':
        this.router.navigate(['/check-in']);
        break;
      case 'experiencias':
        this.router.navigate(['/experiencias']);
        break;
      case 'parceiros':
        this.router.navigate(['/parcerias']);
        break;
      case 'transparencia':
        this.router.navigate(['/transparencia']);
        break;
      case 'ranking':
        this.router.navigate(['/ranking-de-socios']);
        break;
      case 'exclusivo':
        this.router.navigate(['/conteudo-exclusivo']);
        break;
      default:
        break;
    }
  }

  private scrollToSection(menuItem: string) {
    const element = document.getElementById(menuItem.replace(/\s+/g, '-').toLowerCase());
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offsetPosition = elementRect.top + window.scrollY - ((window.innerHeight - elementRect.height) / 2);

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }
}
