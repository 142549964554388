<div class="points-history px-4 py-5">
    <div class="row d-flex justify-content-center">
        <div class="col-md-8">
            <div class="d-flex justify-content-between">
                <p class="h2">Histórico de pontos</p>
                <div class="col-md-2">
                    <app-button [isIcon]="true" [left]="true" routerLink="/ranking-de-socios" [iconName]="'bi bi-arrow-left'"
                        [buttonOptions]="buttonOptions" [style]="'width: 100%;'"></app-button>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center pb-5">
        <div class="col-md-8">
            <p class="h2 total">{{ totalPontos }} pontos</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div *ngFor="let historico of historicoPontos">
                <div class="mb-3">
                    <p class="fs-small pb-2">{{ historico.data | date:'dd/MM/yyyy' }}</p>
                    <div *ngFor="let item of historico.itens"
                        class="d-flex flex-row justify-content-between card p-3 mb-2">
                        <span class="flex-grow-1">{{ item.descricao }}</span>
                        <span class="text-nowrap" [ngClass]="{'success': item.pontos > 0, 'danger': item.pontos < 0}">{{ item.pontos }} pontos</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>