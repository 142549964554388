import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(protected http: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  protected handleError(error: HttpErrorResponse): Observable<never> {
    return this.errorHandlerService.handleError(error);
  }

  protected get<T>(url: string, p0?: any): Observable<T> {
    let params = new HttpParams();
    if (p0?.params instanceof HttpParams) {
      params = p0.params;
    }
    return this.http.get<T>(url, { params: params }).pipe(catchError(this.handleError.bind(this)));
  }

  protected post<T>(url: string, body: any, prms?: any): Observable<T> {
    let params = new HttpParams();
    if (prms?.params instanceof HttpParams) {
      params = prms.params;
    }
    return this.http.post<T>(url, body, {params: params}).pipe(catchError(this.handleError.bind(this)));
  }

  protected put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(url, body).pipe(catchError(this.handleError.bind(this)));
  }

  protected patch<T>(url: string, body?: any): Observable<T> {
    return this.http.patch<T>(url, body).pipe(catchError(this.handleError.bind(this)));
  }

  protected delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url).pipe(catchError(this.handleError.bind(this)));
  }
}
