<div class="container px-4 py-5">
    <div class="d-flex justify-content-end">
        <app-button
          id="back-desktop"
          [isIcon]="true"
          [left]="true"
          [iconName]="'bi bi-arrow-left'"
          [buttonOptions]="backBtn"
          (buttonClick)="back()"
        >
        </app-button>
    </div>
    <div class="convide-amigo">
        <div class="headerPage">
            <h1 class="main-title">
                Convidar amigo
            </h1>
            <p class="subtitle">
                Selecione a forma de convite para trazer um amigo para o Sócio Torcedor
            </p>
            <div class="filters">
                <button (click)="typeActive = 'email'" class="btn-primary" [ngClass]="{'activeButton': typeActive === 'email'}">
                    <img *ngIf="typeActive == 'email'" src="/assets/svg/envelope-escuro.svg" alt="envelope icon">
                    <img *ngIf="typeActive != 'email'" src="/assets/svg/envelope-claro.svg" alt="envelope icon">
                    <p>Email</p>
                </button>
                <button (click)="typeActive = 'redes-sociais'" [ngClass]="{'activeButton': typeActive === 'redes-sociais'}">
                    <img *ngIf="typeActive == 'redes-sociais'" src="/assets/svg/share-escuro.svg" alt="share icon">
                    <img *ngIf="typeActive != 'redes-sociais'" src="/assets/svg/share-claro.svg" alt="share icon">
                    <p>Redes Sociais</p>
                </button>
            </div>
        </div>
        <div class="email" *ngIf="typeActive == 'email'">
            <div class="dadosEmail">
                <p class="subtitle">
                    Um email será enviado para o convidado com todas as instruções para fazer parte do Sócio Torcedor
                </p>
                <form [formGroup]="filterGroup" class="col-12">
                    <app-input
                        label="Email do convidado"
                        [id]="'filterInputId'"
                        [placeholder]="'Insira o email do amigo que será convidado'"
                        formControlName="filterControl"
                    ></app-input>
                </form>
                <div class="buttons justify-content-end justify-content-md-between">
                    <!-- <app-button 
                        [isIcon]="true"
                        [left]="true"
                        [iconName]="'bi bi-arrow-left'"
                        class="mx-2 my-1 d-none d-md-block"
                        id="cancelModalBtn"
                        [buttonOptions]="backBtn"
                        data-bs-dismiss="modal"
                        (buttonClick)="back()">
                    </app-button> -->
                    <app-button
                        [style]="'padding-right: 4rem; padding-left: 4rem;'"
                        class="mx-2 my-1"
                        id="confirmModalBtn"
                        [buttonOptions]="sendBtn">
                    </app-button>
                </div>
            </div>
            <div class="convitesEnviados">
                <h2>Convites enviados</h2>
                <div class="listaEnviados">
                    <div class="title bg-primary">
                      <p class="email">Email</p>
                      <p class="status">Status</p>
                    </div> 
                    <div class="item" *ngFor="let item of convitesEnviados; let i = index" [ngClass]="{'bgGray': i % 2 != 0}">
                      <p class="email">{{ item.email }}</p>
                      <p class="status">{{ item.status }}</p>
                    </div> 
                </div>
            </div>
        </div>
        <div class="redes-sociais" *ngIf="typeActive == 'redes-sociais'">
            <p class="subtitle">
                Utilize o link da rede social que será utilizada para convidar, clique para copiar e colar
            </p>
            <div class="buttons">
                <div class="item">
                    <label for="whatsapp">Whatsapp</label>
                    <button (click)="copyText('https://link.1234567890.com/socio-convida#0001')">
                        <p><i class="fa-brands fa-whatsapp"></i>https://link.1234567890.com/socio-convida#0001</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="facebook">Facebook</label>
                    <button (click)="copyText('https://link.1234567890.com/socio-convida#0001')">
                        <p><i class="fa-brands fa-facebook"></i>https://link.1234567890.com/socio-convida#0001</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="instagram">Instagram</label>
                    <button (click)="copyText('https://link.1234567890.com/socio-convida#0001')">
                        <p><i class="fa-brands fa-instagram"></i>https://link.1234567890.com/socio-convida#0001</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="twitter">Twitter</label>
                    <button (click)="copyText('https://link.1234567890.com/socio-convida#0001')">
                        <p><i class="fa-brands fa-twitter"></i>https://link.1234567890.com/socio-convida#0001</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="tiktok">Tiktok</label>
                    <button (click)="copyText('https://link.1234567890.com/socio-convida#0001')">
                        <p><i class="fa-brands fa-tiktok"></i>https://link.1234567890.com/socio-convida#0001</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
            </div>
            <!-- <app-button 
                [isIcon]="true"
                [left]="true"
                [iconName]="'bi bi-arrow-left'"
                class="mx-2 my-1"
                id="cancelModalBtn"
                [buttonOptions]="backBtn"
                data-bs-dismiss="modal">
            </app-button> -->
        </div>
    </div>
</div>