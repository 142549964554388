import { Component } from '@angular/core';
import { ActivatedRoute, Router,} from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { OperationType } from '../finalizar-pagamento.component';
import { MemberService } from 'src/app/core/services/member.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-seja-bem-vindo',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './seja-bem-vindo.component.html',
  styleUrl: './seja-bem-vindo.component.scss'
})
export class SejaBemVindoComponent {

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  imgWelcoming = '/assets/imagens/image_welcome.png';
  clubName =  localStorage.getItem('clubName');
  welcomeMessage = '';
  infoToDashboard = 'Enviaremos um e-mail com todas as informações para acessar a área exclusiva do sócio.';

  private _destroy$ = new Subject<void>();
  btnGoToLogin = {
    buttonText: 'IR PARA LOGIN',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-4 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  private operation: OperationType;
  plan: string ; //
  memberLoginData: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private authService: AuthService,
    private router: Router
  ) { 
    this.activatedRoute.params.subscribe((param: any) => {
      if (param && Object.keys(param).length) {
        this.operation = Number(param.operation) as OperationType;

        this.setMessageByOperation(this.operation)
      }
    });

    this.plan =  this.memberService.getMemberPlan().planName;
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  setMessageByOperation(operation: OperationType): void {
    switch (operation) {
      case OperationType.NEW:
        this.welcomeMessage = `Parabéns!<br>Agora você é um sócio ${this.clubName}`;
      break;
        case OperationType.UPGRADE:
        this.welcomeMessage = `Parabéns!<br>Você agora faz parte do plano ${this.plan}!`;
      break;
        case OperationType.DOWNGRADE:
        this.welcomeMessage = 'Parabéns!<br>Seu plano foi alterado!';
      break;
      case OperationType.NEWLOGGED:
        this.welcomeMessage = `Parabéns!<br>Agora você é um sócio ${this.clubName}`;
        this.btnGoToLogin.buttonText = 'IR PARA DASHBOARD';
      break;
      default:
        break;
    }
  }
}
