

<div class="container p-4 p-md-5 col-md-7 update-password-page" *ngIf="showForm">
    <div id="update-pass-title" class="update-pass-main-title my-4">
        Criar nova senha
    </div>
    <div id="update-pass-subtitle" class="update-pass-sub-title mt-3 mb-4">
        Mantenha sua conta segura com uma senha de no minimo 8 caracteres
    </div>
    <form [formGroup]="formGroup">
        <div class="row">
            <app-input class="col"
                [id]="'newPasswordInputId'"
                [label]="'Nova senha'"
                [type]="InputTypeEnum.PASSWORD"
                [placeholder]="'Crie uma nova senha'"
                formControlName="newPass"
            ></app-input>
        </div>
        <div class="row">
            <app-input class="col" 
                [id]="'repeatNewPasswordInputId'"
                [label]="'Confirmar senha'"
                [type]="InputTypeEnum.PASSWORD"
                [placeholder]="'Confirme sua nova senha'"
                formControlName="repeatNewPass"
            ></app-input>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <app-button class="my-2 mx-2"
                [disabled]="formGroup.invalid"
                [buttonOptions]="btnSaveOptions"
                (buttonClick)="onSubmit()">
            </app-button>
        </div>
    </form>
</div>
