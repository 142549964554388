import { inject } from '@angular/core';
import { CanActivateFn, NavigationExtras, Router } from '@angular/router';

import { ActivatedRouteSnapshot } from '@angular/router';

export const customGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state) => {
  const router = inject(Router);
  const enableLoggedArea = localStorage.getItem('enableLoggedArea');
  const enableNewMembership = localStorage.getItem('enableNewMembership');
  const enablePortal = localStorage.getItem('enablePortal');
  const messageLoggedArea = localStorage.getItem('messageLoggedArea');
  const messageNewMembership = localStorage.getItem('messageNewMembership');
  const messagePortal = localStorage.getItem('messagePortal');

  let customGuardResponse = true;
  if (enablePortal === 'false'){
    router.navigate([Routes.NotFoundPage, { message: messagePortal }]);
    return false
  }

  switch (route.routeConfig?.path) {
    case Routes.Login:
    case Routes.ChangePassword:
      customGuardResponse = enableLoggedArea === 'true';
      if (!customGuardResponse) router.navigate([Routes.NotFoundPage, { message: messageLoggedArea }]);
      break;
    case Routes.Register:
    case Routes.CompleteData:
    case Routes.FinishPayment:
    case Routes.Welcome:
      customGuardResponse = enableNewMembership === 'true';
      if (!customGuardResponse) router.navigate([Routes.NotFoundPage, { message: messageNewMembership }]);
      break;
    default:
      break;
  }

  return customGuardResponse

};

enum Routes {
  Home = '',
  Login = 'login',
  Register = 'cadastro-socio',
  CompleteData = 'completar-dados',
  FinishPayment = 'finalizar-pagamento/:operation',
  Welcome = 'finalizar-pagamento/:operation/seja-bem-vindo',
  ChangePassword = 'alterar-senha',
  NotFoundPage = '/not-found-page',
}