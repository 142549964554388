<div class="notification-dropdown d-flex align-items-center h-100" [ngStyle]="styles">
  <div class="dropdown">

    <div class="p-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-bell-fill d-flex fs-5" role="button" title="Notificações"></i>
      @if (notificationsBadge()) {
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {{notificationsBadge()}}
          <span class="visually-hidden">unread messages</span>
        </span>
      }
    </div>

    <div class="dropdown-menu dropdown-menu-right">
      <div class="d-flex justify-content-between align-items-center flex-column">
        <div class="align-items-center d-grid d-sm-flex justify-content-between p-3 pt-2 w-100">
          <h6 class="dropdown-header p-0 fs-6 mb-2 mb-sm-0 text-body">Notificações</h6>
          <a role="button" (click)="markAllAsRead();$event.stopPropagation()"
            class="fs-6 text-decoration-none text-muted text-nowrap">Marcar todas como lidas</a>
        </div>
        <div class="d-flex flex-column w-100">
          @if (notifications() && notifications().length) {
            @for (notification of notifications().slice(0, maxNotifications); track $index) {
              <button class="d-flex p-1 pb-2 dropdown-item px-3" (click)='viewNotification(notification)'
                [ngClass]="{'no-viwed': !notification.isViewed}" type="button">
                <i class="bi bi-person-circle fs-2 me-3"></i>
                <div class="container p-0 pe-5">
                  <div class="row">
                    <span class="notification-title col-12 mb-2 d-inline-block text-truncate">{{notification.title}}</span>
                    <p class="d-inline-block text-truncate col-12 notification-message">
                      {{notification.subject}}
                    </p>
                  </div>
                </div>
              </button>
            }
          } @else {
            <div class="p-3">
              Não existem notificações.
            </div>
          }
          @if (showAllNotifications()) {
            <div class="dropdown-divider"></div>
            <button class="dropdown-item d-flex justify-content-center" (click)="viewAllNotifications()">
              Ver todas
            </button>
          }
        </div>

      </div>
    </div>
  </div>
</div>
