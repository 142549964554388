import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base/base.service';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';
import { Page } from 'src/app/shared/models/Page';
import { GeneralConfigModel } from 'src/app/shared/models/generalConfigModel';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService extends BaseService {
 
  constructor(http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
    super(http, errorHandlerService);
  }

  private env = environment;
 

  getExperiences(): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/experiences`);
  }

}
