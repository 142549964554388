<div class="container p-4 p-md-5 complete-data" id="complete-data-page">
    <div class="logo-sou-mais-fc-page p-3 text-center">
        <img [src]="logoUrl" alt="Logo">
    </div>
    <div class="complete-data-main-title my-4 text-center">
        {{pageTitle}}
    </div>
    <form [formGroup]="partnerDataformGroup">
        <div class="row">
            <app-input-custom class="col-md-3"
                [id]="'CEPInputId'"
                [label]="'CEP'"
                [mask]="InputMaskTypesEnum.CEP"
                formControlName="CEPControl"
                (input)="getAddressByCEP()"></app-input-custom>
            <app-input class="col-md-6"
                [id]="'addressInputId'"
                [label]="'Endereço'"
                [placeholder]="'Informe seu endereço'"
                formControlName="addressControl"></app-input>
            <app-input class="col-md-3"
                [id]="'numberInputId'"
                [label]="'Número'" 
                [placeholder]="'Informe o Número'"
                [type]="InputTypeEnum.NUMBER"
                formControlName="numberControl"
            ></app-input>
        </div>
        <div class="row">
            <app-input class="col-md-4"
                [id]="'neighborhoodInputId'"
                [label]="'Setor/Bairro'"
                [placeholder]="'Insira o bairro'"
                formControlName="neighborhoodControl"></app-input>
            <app-select-input class="col-md-4"
                [id]="'stateInputId'" 
                [label]="'Estado'"
                [options]="stateOptions"
                formControlName="stateControl"
                (change)="getCity()"
            ></app-select-input>
            <app-select-input class="col-md-4"
                [id]="'cityInputId'" 
                [label]="'Cidade'"
                [options]="cityOptions"
                formControlName="cityControl"
            ></app-select-input>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end my-3">
            <app-button
                id="submitBtn"
                [disabled]="partnerDataformGroup.invalid"
                [buttonOptions]="submitBtn"
                [isIcon]="true" [right]="true"
                [iconName]="'bi bi-arrow-right'"
                (buttonClick)="onSubmit()">
            </app-button>
        </div>
    </form>

</div>
