import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreditCardInfoModel, PaymentSummaryItem, PaymentType } from 'src/app/shared/models/paymentData';
import { BaseService } from './base/base.service';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentDataService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getCreditCardInfo(): Observable<CreditCardInfoModel[]> {
    return this.get<CreditCardInfoModel[]>('assets/mock/creditCardInfoMockData.json');
  }

  // getSummaryInfo(formOfPayment: PaymentType): Observable<PaymentSummaryItem[]> {
  //   if (formOfPayment === PaymentType.CREDITO) {
  //     return this.get<PaymentSummaryItem[]>('assets/mock/summaryCreditModeMockData.json');
  //   } else {
  //     return this.get<PaymentSummaryItem[]>('assets/mock/summaryRegularModeMockData.json');
  //   }


  // }

  sendPaymentData(paymentData: PaymentDataPayload): Observable<PaymentDataResponse> {

    return this.post<PaymentDataResponse>(`${this.env.publicApiSocioUrl}v1/socio/charger`, paymentData);
  }

  getPaymentOrder(paymentId: string): Observable<PaymentDataResponse> {
    let params = new HttpParams();
    params = params.append('showLoader', 'false');
    return this.get<PaymentDataResponse>(`${this.env.publicApiSocioUrl}v1/socio/orders/${paymentId}`,{params});
  }

}

export interface PaymentDataPayload {
  cardNumber: string;
  cardHolderName: string;
  cardCvv: string;
  cardExpirationDate: string;
  installments: number;
  amount?: number;
  planId: string;
  memberId: string;
  paymentMethodId: string;
  planPaymentId: string;
  periodicityId: string;
}

export interface PaymentDataResponse {
  id: string;
  status: string;
  qrCodeData: string;
  qrCodeImageUrl: string;
  expiresIn: number;
  error: string;
  declinedCode: string;
  code: number;
  message: string;
}