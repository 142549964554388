import { Component, Input, OnInit } from '@angular/core';
import { LayoutConfigService } from '../../services/layout-config.service';
import { CommonModule } from '@angular/common';
import { AppInputComponent, InputType } from '../app-input/app-input.component';

@Component({
  selector: 'app-ranking-table',
  standalone: true,
  imports: [
    CommonModule,
    AppInputComponent
  ],
  templateUrl: './ranking-table.component.html',
  styleUrl: './ranking-table.component.scss'
})
export class RankingTableComponent implements OnInit{
  InputTypeEnum = InputType;
  @Input() rows: Row[] = [];
  @Input() tableConfig: any;

  constructor( private layoutConfigService: LayoutConfigService ) {}

  public styles: { [key: string]: any };
  public buttonOptions: any;

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.styles = {
      '--border-radius': layoutVersion === 1 ? '15px' : '0px'
    };
    
  }

}

interface Row {
  ranking: number;
  photo: string;
  socio: string;
  cidade: string;
  pontos: number;
  trend?: 'up' | 'down';
}