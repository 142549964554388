import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { RouterLink } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resgates',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink],
  templateUrl: './resgates.component.html',
  styleUrl: './resgates.component.scss'
})
export class ResgatesComponent implements OnInit{
  currentUrl = '';
  backBtn = {
    buttonText: 'Voltar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-secondary',
    fontSize: 14
  };

  constructor(
    private location: Location) { }

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }
}
