import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NO_AUTH } from '../no-auth.inteceptor';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  baseUrl: string = `https://viacep.com.br/ws/`;

  constructor(private http: HttpClient) {
  }

   searchCep(cep: number): Observable<any> {
    return this.http.get(this.baseUrl + cep + `/json/`, {
      context: new HttpContext().set(NO_AUTH, true)
    });
  }


}

