import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { AppSelectInputComponent, SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { PartnerData } from 'src/app/shared/models/partnerData';
import { OperationType } from '../finalizar-pagamento/finalizar-pagamento.component';
import { MemberService } from 'src/app/core/services/member.service';
import { CommonService } from 'src/app/core/services/common.service';
import { CepService } from 'src/app/core/services/cep.service';

@Component({
  selector: 'app-completar-dados',
  standalone: true,
  imports: [ReactiveFormsModule, AppInputCustomComponent, AppInputComponent, AppSelectInputComponent, AppButtonComponent],
  templateUrl: './completar-dados.component.html',
  styleUrl: './completar-dados.component.scss'
})
export class CompletarDadosComponent implements OnInit {
  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Complemente seus Dados';
  memberId: string = '';

  protected stateOptions = [] as SelectModel[];

  protected cityOptions = [] as SelectModel[];

  submitBtn = {
    primaryColor: '#E0C252',
    secondaryColor: '#191844',
    buttonText: 'Prosseguir',
    buttonTextColor: '#191844',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonBorderColor: 'none',
    buttonSize: 'btn btn-sm px-4',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  partnerDataformGroup = new FormGroup({
    CEPControl: new FormControl('', [Validators.required]),
    addressControl: new FormControl('', [Validators.required]),
    numberControl: new FormControl('', [Validators.required]),
    neighborhoodControl: new FormControl('', [Validators.required]),
    stateControl: new FormControl('', [Validators.required]),
    cityControl: new FormControl('', [Validators.required])
  });

  constructor(private router: Router,
    private memberService: MemberService,
    private commonService: CommonService,
    private cepService: CepService
  ) {

    this.memberId = this.memberService.getMemberId();
    if (!this.memberId) {
      this.router.navigate(['/cadastro-socio']);
    }
    this.getState();

  }

  ngOnInit(): void {
  }



  get controls(): any {
    return this.partnerDataformGroup.controls;
  }

  onSubmit(): void {
    if (this.partnerDataformGroup.valid) {
      // Handle form submission
      const partnerData = this.buildPayloadPartnerData(this.partnerDataformGroup);
      this.postMember(partnerData);
    } else {
      // Handle form errors
      console.log('Form is invalid');
    }
  }

  private buildPayloadPartnerData(formGroup: FormGroup): PartnerData {
    return {
      area: formGroup.get('addressControl')?.value,
      cityCode: parseInt(formGroup.get('cityControl')?.value),
      memberId: this.memberId,
      neighborhood: formGroup.get('neighborhoodControl')?.value,
      number: parseInt(formGroup.get('numberControl')?.value),
      stateCode: parseInt(formGroup.get('stateControl')?.value),
      zipCode: parseInt(formGroup.get('CEPControl')?.value)
    };
  }


  getAddressByCEP(): void {
    const cep = this.partnerDataformGroup.get('CEPControl')?.value as string;
    if (cep.length === 8) {

      this.cepService.searchCep(Number(cep)).subscribe(
        (response) => {
          this.partnerDataformGroup.reset();
          this.partnerDataformGroup.get('CEPControl')?.setValue(response.cep);
          this.partnerDataformGroup.get('addressControl')?.setValue(response.logradouro);
          this.partnerDataformGroup.get('neighborhoodControl')?.setValue(response.bairro);
          this.partnerDataformGroup.get('stateControl')?.setValue(this.stateOptions.find((state) => state.name === response.estado)?.code as string);
          this.getCity(true);
         
        }
      );
    }
  }

  getState(): void {
    this.commonService.getStatePublic().subscribe(
      (response) => {
        this.stateOptions = response.map((state: any) => {
          return {
            code: state.id,
            name: state.name
          } as SelectModel;
        }
        );
      });
  }

  getCity(setValue?: boolean): void {
    this.commonService.getCity(this.partnerDataformGroup.get('stateControl')?.value).subscribe(
      (response) => {
        this.cityOptions = response.map((city: any) => {
          return {
            code: city.id,
            name: city.name
          } as SelectModel;
        }
        );
        if (setValue) {
          this.partnerDataformGroup.get('cityControl')?.setValue(this.cityOptions[0].code);
        }
      });
  }

  postMember(partnerData: any) {
    this.memberService.postMemberAddress(partnerData).subscribe(
      (response) => {
        this.router.navigate([`/finalizar-pagamento/${OperationType.NEW}`]);
      }
    );
  }

}
