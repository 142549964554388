<div class="pt-4 pt-md-5 px-md-3 px-lg-0" [ngStyle]="styles">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="backOptions"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div class="partner-data-main-title mt-2 mb-5" [style.color]="labelColor">
        Ranking de Sócios
    </div>
    <div class="row mb-3">
        <div class="col-6 col-md-3 mb-3" *ngFor="let info of infos">
            <app-info-card [title]="info.title" [value]="info.value" [icon]="info.icon"
                [extraText]="info.extraText"></app-info-card>
        </div>
    </div>
    <div class="row mb-4 d-flex flex-column flex-md-row">
        <div class="col mb-4">
            <div class="card">
                <div class="card-body card-estatistica">
                    <h5 class="h5 mb-3 hl">Suas Estatísticas</h5>
                    <p class="h6">Melhor posição no ranking: <span class="hl h4"> 1º </span></p>
                    <p class="h6">Pior posição no ranking: <span class="hl h4"> 53º </span></p>
                    <p class="h6">Tempo total no ranking: <span class="hl h4"> 321 dias </span></p>
                    <p class="h6">Máximo de pontos acumulado: <span class="hl h4"> 5840 pontos </span></p>
                    <p class="h6">Experiências resgatadas: <span class="hl h4"> 2 </span></p>
                </div>
            </div>
        </div>
        <div class="col mb-2">
            <div class="card">
                <div class="card-body card-historico">
                    <h2 class="h5 hl">Histórico de pontos</h2>
                    <p class="data mb-2">08/03/2024</p>
                    <div class="">
                        <div class="d-flex justify-content-between">
                            <span class="h6 m-0">Renovação anual de plano</span>
                            <span class="color-primary">300 pontos</span>
                        </div>
                        <hr class="hr" />
                    </div>
                    <div class="">
                        <div class="d-flex justify-content-between">
                            <span class="h6 m-0">Experiência "Camiseta exclusiva" resgatado</span>
                            <span>-250 pontos</span>
                        </div>
                        <hr class="hr" />
                    </div>
                    <div class="">
                        <div class="d-flex justify-content-between">
                            <span class="h6 m-0">Amigo convidado</span>
                            <span class="color-primary">100 pontos</span>
                        </div>
                        <hr class="hr" />
                    </div>
                    <div class="d-flex justify-content-center">
                        <app-button routerLink="/historico-pontos" [buttonOptions]="buttonOptions"></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <app-ranking-table [rows]="rows"></app-ranking-table>
    </div>

</div>