import { CommonModule } from '@angular/common';
import { Component, OnInit, WritableSignal, computed, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { MemberService } from 'src/app/core/services/member.service';
import { CommonService, DateMode } from 'src/app/core/services/common.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PaymentDataPayload, PaymentDataService } from 'src/app/core/services/payment-data.service';
import { AppToggleButtonComponent, BtnToggleOptions } from 'src/app/shared/components/app-button-group/app-toggle-button.component';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import { AppSelectInputComponent, SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { CardPlanItemsComponent } from 'src/app/shared/components/card-plan-items/card-plan-items.component';
import { CreditCardInfoModel, PaymentInfoModel, PaymentType } from 'src/app/shared/models/paymentData';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { PlanService } from 'src/app/core/services/plan.service';
import { PaymentModalComponent } from 'src/app/shared/components/payment-modal/payment-modal.component';
import { ModalService } from 'src/app/core/services/modal.service';
import { AdicionarCartaoComponent } from '../adicionar-cartao/adicionar-cartao.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { Plan } from 'src/app/shared/models/planModel';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-finalizar-pagamento',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AppSelectInputComponent, AppCheckboxComponent, AppButtonComponent,
    AppToggleButtonComponent, CustomCurrencyPipe, CardPlanItemsComponent, RouterModule, PaymentModalComponent, AdicionarCartaoComponent,
    AppInputCustomComponent, AppInputComponent],
  templateUrl: './finalizar-pagamento.component.html',
  styleUrl: './finalizar-pagamento.component.scss'
})
export class FinalizarPagamentoComponent implements OnInit {


  // TODO: Rever estrutura de color
  labelColor = 'white';
  screenWidth: number;

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;
  DateMode = DateMode;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Finalize o pagamento';
  PaymentType = PaymentType;
  paymentType = PaymentType.CREDITO;
  paymentModalData: any;
  canChekPix = true;

  modalId = 'payment-modal';

  finalizePaymentformGroup = new FormGroup({
    planControl: new FormControl<string>('', Validators.required),
    dependentControl: new FormControl<string>('0'),
    frequencyControl: new FormControl<string>('', Validators.required),
    invoiceRecurrenceControl: new FormControl<boolean>(true),
    formOfPaymentControl: new FormControl<string>('', Validators.required),
    creditCardControl: new FormControl<string>(''),

  });
  creditCardformGroup = new FormGroup({
    cardNumberControl: new FormControl<string>(''),
    expirationDateControl: new FormControl<string>(''),
    cvvControl: new FormControl<string>(''),
    cardNameControl: new FormControl<string>(''),
    CPFControl: new FormControl<string>(''),
    numberOfInstallmentsControl: new FormControl<string>('')
  });

  creditCardInfo: WritableSignal<CreditCardInfoModel[]> = signal([]);

  creditCardOptions = computed<SelectModel[]>(() => {
    if (!this.creditCardInfo()) {
      return [];
    }

    return this.creditCardInfo().map(cardInfo => {
      return {
        code: cardInfo.cardNumber, // Use card number as code
        name: `${cardInfo.cardOperator} - ....${cardInfo.cardNumber.slice(-4)}`,
      } as SelectModel;
    });
  });

  paymentSummary: any;
  paymentMethodType: any

  frequencyOptions: BtnToggleOptions[] = [];

  formOfPaymentOptions: BtnToggleOptions[] = [];

  buttonOptions = {
    buttonText: 'FINALIZAR',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonBorderColor: 'none',
    buttonSize: 'btn btn-sm py-2 px-4 w-100 btn-primary',
  };

  buttonAddCardOptions = {
    buttonText: '+ Adicionar cartão',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-primary',
  };
  showCreditCardOptions = false;

  private _destroy$ = new Subject<void>();

  numberOfInstallmentsOptions: SelectModel[] = [
    { code: '1', name: '1' },
    { code: '2', name: '2' },
    { code: '3', name: '3' },
    { code: '4', name: '4' },
    { code: '5', name: '5' },
    { code: '6', name: '6' }
  ]

  desiredOrderFrequency = ['ANUAL', 'SEMESTRAL', 'TRIMESTRAL', 'MENSAL'];

  protected planOptions = [] as SelectModel[];
  dependentsOptions = [] as SelectModel[];

  planItem: any;
  plans: Plan[] = [];
  memberPlan: any;

  private memberId: string;
  isLoggedIn = false;

  private operation: OperationType;

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private paymentDataService: PaymentDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private planService: PlanService,
    private modalService: ModalService,
    private alertService: AlertService,
    private authService: AuthService,
  ) {
    this.authService.isLoggedIn.subscribe(status => {
      this.isLoggedIn = status;
    });

    this.activatedRoute.params.subscribe((param: any) => {
      if (param && Object.keys(param).length) {
        this.operation = param.operation as OperationType;
        if (this.operation == OperationType.UPGRADE || this.operation == OperationType.NEW) {
          this.memberPlan = this.memberService.getMemberPlan();
        }
      }
    });
    this.memberId = this.memberService.getMemberId();
    if (!this.memberId) {
      this.router.navigate(['/cadastro-socio']);
    }
  }


  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.getPlans();
    this.finalizePaymentformGroup.controls.planControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.onPlanChange(value as string);
        this.getSummaryInfo();
      });

    this.finalizePaymentformGroup.controls.formOfPaymentControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.setPixIconColor(value as string);
        this.updateSummary(value as PaymentType);
        this.getSummaryInfo();

      });
    this.finalizePaymentformGroup.controls.frequencyControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {

        this.onFrequencyChange(value as string);
        this.getSummaryInfo();
      });

    // TODO: Get data from server
    const paymentInfo = {
      plan: '',
      frequency: '',
      invoiceRecurrence: true,
      formOfPayment: '',
      creditCard: '',
      numberOfInstallments: ''
    } as PaymentInfoModel;
    this.initializeForm(this.finalizePaymentformGroup, paymentInfo);

    this.setCreditCardArr();

  }

  updateValueOfPayment() { 
    this.finalizePaymentformGroup.controls.formOfPaymentControl.setValue(null)
    this.showCreditCardOptions = false;
    this.paymentSummary = null
  }

  getPlans(): void {
    this.planService.getPublicPlans().subscribe((plans: any) => {
      this.plans = plans.content;
      this.planOptions = plans.content.map((plan: any) => {
        return {
          code: plan.id,
          name: plan.planName
        } as SelectModel;
      });
      if (this.memberPlan?.id) {
        this.finalizePaymentformGroup.get('planControl')?.setValue(this.memberPlan.id);
      }
    })
  }

  onPlanChange(value: string): void {
    this.planItem = this.plans?.find((plan: any) => plan.id === value) as Plan;
    if (!this.planItem) {
      return;
    }
    this.planItem.enableItems = this.planItem?.benefits?.filter((benefit: any) => benefit?.isActive).map((benefit: any) => benefit?.benefitName) as string[];
    // this.planItem.disableItems = this.planItem?.benefits?.filter((benefit: any) => !benefit?.isActive).map((benefit: any) => benefit?.benefitName) as string[],


    this.frequencyOptions = this.planItem?.payments?.map((payment: any) => {
      return {
        label: payment.period.description,
        value: payment.period.id
      } as BtnToggleOptions;
    });

    this.frequencyOptions = this.frequencyOptions?.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.label === item.label
      ))
    );

    this.frequencyOptions.sort((a, b) => {
      return this.desiredOrderFrequency.indexOf(a.label) - this.desiredOrderFrequency.indexOf(b.label);
    });
  }

  onFrequencyChange(value: string): void {
    this.setNumberOfInstallments(this.frequencyOptions.find((item) => item.value === value)?.label as string)
    this.formOfPaymentOptions = this.planItem?.payments?.filter((payment: any) => {
      return payment.period.id === this.finalizePaymentformGroup.controls.frequencyControl.value;
    })
      .map((paymentItem: any) => {
        // Definindo a label
        let label = paymentItem.paymentMethodType.name;

        if (this.screenWidth <= 470 && paymentItem.paymentMethodType.name === "Cartão de Crédito") {
          label = "C. de Crédito"; // 
        }
        return {
          label: label,
          value: paymentItem.paymentMethodType.id,
          icon: paymentItem.paymentMethodType.name == "PIX" ? 'pix-darker' : 'bi bi-credit-card',
          iconComponent: paymentItem.paymentMethodType.name == "PIX" ? 'mat-icon' : null, 
        } as BtnToggleOptions;
      });
    this.formOfPaymentOptions = this.formOfPaymentOptions?.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.label === item.label
      ))
    );

  }

  setNumberOfInstallments(value: string): void {
    let maxInstallments;
    switch (value) {
      case 'ANUAL':
        maxInstallments = localStorage.getItem('yearMaximumNumberInstallment') as string;
        break;
      case 'SEMESTRAL':
        maxInstallments = localStorage.getItem('semiAnnualMaximumNumberInstallment') as string;
        break;
      case 'TRIMESTRAL':
        maxInstallments = localStorage.getItem('quarterlyMaximumNumberInstallment') as string;
        break;    
      default:
        maxInstallments = '0';
        break;
    }
    this.numberOfInstallmentsOptions = [];
    for (let index = 1; index <= parseInt(maxInstallments); index++) {
      this.numberOfInstallmentsOptions.push({ code: index.toString(), name: index.toString() });
    }    
  }


  async handleCreditCardInfo(creditCardArr: CreditCardInfoModel[]): Promise<CreditCardInfoModel[]> {
    if (!creditCardArr.length) {
      try {
        const fetchedData = await lastValueFrom(this.paymentDataService.getCreditCardInfo());
        this.localStorageService.setItem('credit-card-info', fetchedData);
        return fetchedData; // Return the fetched data
      } catch (error) {
        console.error('Error fetching credit card info:', error);
        throw error;
      }
    }
    return creditCardArr; // Return existing data if no fetch needed
  }

  async setCreditCardArr() {
    try {
      const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
      const updatedArr = await this.handleCreditCardInfo(creditCardArr);
      this.creditCardInfo.set(updatedArr);
    } catch (error) {
      console.error('Error setting credit card info:', error);
      // Handle error appropriately, e.g., show an alert to the user
    }
  }

  getSummaryInfo(): void {
    if (!this.finalizePaymentformGroup.get('formOfPaymentControl')?.value
      || !this.finalizePaymentformGroup.get('planControl')?.value
      || !this.finalizePaymentformGroup.get('frequencyControl')?.value) {
      return
    }
    this.paymentMethodType = this.planItem?.payments.filter((payment: any) => payment.paymentMethodType.id === this.finalizePaymentformGroup.get('formOfPaymentControl')?.value);
    if (this.paymentMethodType.length > 1) {
      this.paymentMethodType = this.paymentMethodType.filter((payment: any) => payment.period.id === this.finalizePaymentformGroup.get('frequencyControl')?.value);
    }

    this.setDependentsOptions();
    this.updateValueWithDependents();

  }

  updateValueWithDependents(): void {
    if (this.finalizePaymentformGroup.get('dependentControl')?.value) {
      this.paymentSummary = this.paymentMethodType.filter((payment: any) => payment.qtyDependents == this.finalizePaymentformGroup.get('dependentControl')?.value)[0];
    }

  }


  private setPixIconColor(value: string) {
    this.formOfPaymentOptions?.find(item => {
      if (item.label === PaymentType.PIX) {
        let valueLabel = this.getPaymentFormLabel(value);
        if (valueLabel === PaymentType.PIX) {
          item.icon = 'pix-white';
        } else {
          item.icon = 'pix-darker';
        }
      }
    });
  }

  updateSummary(formOfPayment: PaymentType): void {
    if (!formOfPayment) return;
    let formOfPaymentLabel = this.getPaymentFormLabel(formOfPayment);

    this.getSummaryInfo();

    if (formOfPaymentLabel === PaymentType.CREDITO || formOfPaymentLabel === PaymentType.CREDITO_ABREVIADO) {
      this.showCreditCardOptions = true;
      this.setCreditFieldRequired();
    } else {
      this.showCreditCardOptions = false;
      this.creditCardformGroup.reset();
      this.clearCreditFieldValidators();
    }
  }

  setDependentsOptions(): void {
    this.finalizePaymentformGroup.get('dependentControl')?.setValue('0');
    this.dependentsOptions = [];
    let qtyDependents = this.paymentMethodType?.map((payment: any) => payment.qtyDependents);
    let maxDependents = Math.max(...qtyDependents);
    for (let index = 0; index <= maxDependents; index++) {
      this.dependentsOptions.push({ code: index.toString(), name: index.toString() === '0' ? 'Sem Dependentes' :  index.toString()});
    }
  }

  save(): void {
    let paymentPayload: PaymentDataPayload = {
      cardNumber: this.creditCardformGroup.get('cardNumberControl')?.value || '',
      cardHolderName: this.creditCardformGroup.get('cardNameControl')?.value || '',
      cardCvv: this.creditCardformGroup.get('cvvControl')?.value || '',
      cardExpirationDate: this.creditCardformGroup.get('expirationDateControl')?.value?.replace(/\/(\d{2})$/, "/20$1") || '',
      installments: parseInt(this.creditCardformGroup.get('numberOfInstallmentsControl')?.value || '1'),
      planId: this.finalizePaymentformGroup.get('planControl')?.value || '',
      memberId: this.memberId,
      paymentMethodId: this.finalizePaymentformGroup.get('formOfPaymentControl')?.value || '',
      planPaymentId: this.paymentSummary.id,
      periodicityId: this.finalizePaymentformGroup.get('frequencyControl')?.value || '',
    };
    
    let paymentType = this.getPaymentFormLabel(this.finalizePaymentformGroup.get('formOfPaymentControl')?.value as string);

    this.paymentDataService.sendPaymentData(paymentPayload).subscribe((data: any) => {
      if (data.code == 400) {
        this.alertService.showAlert(AlertType.DANGER, data.message);
        this.creditCardformGroup.reset();
      } else {
        if (paymentType === PaymentType.CREDITO || paymentType === PaymentType.CREDITO_ABREVIADO) {
          if (data.status = 'paid') {
            this.memberService.setMemberPlan(this.planItem);
            if (this.isLoggedIn) {
              this.router.navigate(['/finalizar-pagamento/3/seja-bem-vindo']);
            } else {
              this.router.navigate(['/finalizar-pagamento/0/seja-bem-vindo']);
            }
          }
        } else {
          this.paymentModalData = {
            invoiceNumer: data.id,
            invoiceCode: data.qrCodeData,
            invoiceCodeImg: data.qrCodeImageUrl
          }
          console.log(this.paymentModalData)
          this.paymentType = PaymentType.PIX;
          this.checkPixPayment(data.orderId);
          this.modalService.open(this.modalId);
        }
      }
    });
  }

  checkPixPayment(orderId: any): void {
    this.paymentDataService.getPaymentOrder(orderId).subscribe((data) => {
      if (data.status != 'paid') {
        if (this.canChekPix) {
          setTimeout(() => {
            this.checkPixPayment(orderId);
          }, 3000);
        }
      } else {
        this.memberService.setMemberPlan(this.planItem);
        this.modalService.close(this.modalId);
        if (this.isLoggedIn) {
          this.router.navigate(['/finalizar-pagamento/3/seja-bem-vindo']);
        } else {
          this.router.navigate(['/finalizar-pagamento/0/seja-bem-vindo']);
        }
      }
    });
  }

  private initializeForm(formGroup: FormGroup, paymentInfo: PaymentInfoModel): void {
    if (Object.keys(paymentInfo).length > 0) {
      formGroup.get('planControl')?.setValue(paymentInfo?.plan);
      formGroup.get('frequencyControl')?.setValue(paymentInfo?.frequency);
      formGroup.get('invoiceRecurrenceControl')?.setValue(paymentInfo?.invoiceRecurrence);
      formGroup.get('formOfPaymentControl')?.setValue(paymentInfo?.formOfPayment);
      formGroup.get('dependentControl')?.setValue(paymentInfo?.dependents);
      if (paymentInfo?.formOfPayment === PaymentType.CREDITO || paymentInfo?.formOfPayment === PaymentType.CREDITO_ABREVIADO) {
        formGroup.get('creditCardControl')?.setValue(paymentInfo?.creditCard);
        formGroup.get('numberOfInstallmentsControl')?.setValue(paymentInfo?.numberOfInstallments);
        this.setCreditFieldRequired();
      }
    }
  }

  private setCreditFieldRequired(): void {
    this.creditCardformGroup.controls.cardNumberControl.setValidators(Validators.required);
    this.creditCardformGroup.controls.expirationDateControl.setValidators(Validators.required);
    this.creditCardformGroup.controls.cvvControl.setValidators(Validators.required);
    this.creditCardformGroup.controls.cardNameControl.setValidators(Validators.required);
    this.creditCardformGroup.controls.CPFControl.setValidators(Validators.required);
    this.creditCardformGroup.controls.numberOfInstallmentsControl.setValidators(Validators.required);
    this.creditCardformGroup.controls.cardNumberControl.updateValueAndValidity();
    this.creditCardformGroup.controls.expirationDateControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cvvControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cardNameControl.updateValueAndValidity();
    this.creditCardformGroup.controls.CPFControl.updateValueAndValidity();
    this.creditCardformGroup.controls.numberOfInstallmentsControl.updateValueAndValidity();

  }

  private clearCreditFieldValidators(): void {
    this.creditCardformGroup.controls.cardNumberControl.clearValidators();
    this.creditCardformGroup.controls.expirationDateControl.clearValidators();
    this.creditCardformGroup.controls.cvvControl.clearValidators();
    this.creditCardformGroup.controls.cardNameControl.clearValidators();
    this.creditCardformGroup.controls.CPFControl.clearValidators();
    this.creditCardformGroup.controls.numberOfInstallmentsControl.clearValidators();
    this.creditCardformGroup.controls.cardNumberControl.updateValueAndValidity();
    this.creditCardformGroup.controls.expirationDateControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cvvControl.updateValueAndValidity();
    this.creditCardformGroup.controls.cardNameControl.updateValueAndValidity();
    this.creditCardformGroup.controls.CPFControl.updateValueAndValidity();
    this.creditCardformGroup.controls.numberOfInstallmentsControl.updateValueAndValidity();
  }


  getPaymentFormLabel(formOfPaymentId: string): string {
    return this.formOfPaymentOptions.find((payment: any) => payment.value === formOfPaymentId)?.label || '';
  }

  getFrequencyFormLabel(formOfFrequencyId: string): string {
    return this.frequencyOptions.find((payment: any) => payment.value === formOfFrequencyId)?.label || '';
  }

  onModalConfirm(): void { }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
    this.canChekPix = false;
  }
}

export enum OperationType {
  NEW = 0,
  UPGRADE = 1,
  DOWNGRADE = 2,
  NEWLOGGED = 3
}
