import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { UserActionsComponent } from 'src/app/shared/components/user-actions/user-actions.component';
import { TruncateTextDirective } from 'src/app/shared/directives/truncate-text.directive';
import { UserModel } from 'src/app/shared/models/userModel';

@Component({
  selector: 'user-dropdown',
  standalone: true,
  imports: [CommonModule, UserActionsComponent, TruncateTextDirective],
  templateUrl: './user-dropdown.component.html',
  styleUrl: './user-dropdown.component.scss'
})
export class UserDropdownComponent {

  userInfo = input.required<UserModel>();
  styles = input.required<any>();
  userBlocked = input<boolean>();

  userInfoMock = {
    rank: '1º',
    score: '1000',
    photo: 'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'
  }


}
