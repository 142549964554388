<section class="section-planos" [ngStyle]="styles">
    <div class="container p-4">
        <div [ngClass]="classes">
            <div class="wrapper-planos" [ngClass]="classes">
                <p class="h2 title">{{title}}</p>
                <p *ngIf="showSubtitle" class="h6 subtitle">{{subTitle}}</p>
            </div>
        </div>
        <div class="wrapper-planos">
            <div id="carouselCardBeneficios" class="carousel slide" [attr.data-bs-ride]="styles.carouselType">

                <ol class="carousel-indicators" *ngIf="showIndicators">
                    <li *ngFor="let chunk of chunkedItems; let i = index" data-bs-target="#carouselCardBeneficios"
                        [attr.data-bs-slide-to]="i" [class.active]="i === 0">
                    </li>
                </ol>

                <div class="carousel-inner">
                    <ng-container *ngFor="let chunk of chunkedItems; let i = index">
                        <div class="carousel-item" [class.active]="i === 0" [attr.data-bs-interval]='styles.carouselInterval ? (styles.carouselInterval*1000) : "1000"'>
                            <div class="cards-wrapper flex-nowrap">
                                <div class="card col-12 col-md-6 col-lg-3" [ngClass]="{'active':isMobile && j === 0}"
                                    *ngFor="let parceiro of chunk; let j = index">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <img [src]="parceiro.src">
                                            <div class="d-flex flex-column align-items-end">
                                                <strong class="card-discount">{{parceiro.discount}}</strong>
                                                <span>de desconto</span>
                                            </div>
                                        </div>

                                        <h5 class="card-title fs-small">
                                            {{ parceiro.title }}
                                        </h5>
                                        <div class="card-conteudo">
                                            <div class="conteudo" [innerHTML]="parceiro.conteudo"></div>
                                        </div>
                                        <p class="descricao">{{ parceiro.descricao }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <a class="carousel-control-prev" href="#carouselCardBeneficios" role="button" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselCardBeneficios" role="button" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </a>
            </div>
        </div>
    </div>
</section>