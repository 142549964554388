<div class="pt-4 pb-3 pt-md-5 px-md-3 px-lg-0" id="dashboard-page" *ngIf="showDashboard">
    <div class="hello-user my-3">
        <label for="">Olá {{user.name.split(' ')[0]}}!</label>
    </div>
    <div class="row g-3">
        @if(plan){
            <div class="col-12 col-md-8">
                <app-my-plan-card (heightEvent)="setHeight($event)" [plan]="plan"></app-my-plan-card>
            </div>
        }
        <!-- Card Meu Plano -->
        <!-- <div class="col-12 col-md-4">
            <div class="card partner-card">
                <div class="card-body row user-info">
                    <div class="card-title">Dados de Sócio</div> -->
        <!-- Card Última Fatura -->
        <div  class="col-12 col-md-4">
            <div #invoiceCard id="invoiceCard" class="card last-invoice-card">
                <div class="card-body row user-info">
                        <div class="card-title">Última Fatura</div>
                        <div class=" py-1 col-12 col-md-12"><b>Vencimento:</b> {{lastInvoice?.dueDate | date:'dd/MM/yyyy' : '+0000'}}</div>
                        <div class=" py-1 col-12 col-md-12"><b>Referência:</b> {{lastInvoice?.refMonth ?  lastInvoice?.refMonth + '/' +  lastInvoice?.refYear : '-'}}</div>
                        <div class=" py-1 col-12 col-md-12"><b>Status:</b> {{lastInvoice?.invoicePaymentStatus?.statusTypeName}}</div>
                        <div class=" py-1 col-12 col-md-12"><b>Tipo:</b> {{lastInvoice?.invoiceType?.name}}</div>

                    <div class="col-6 col-md-12 p-0 px-1 mt-2">
                        <div class="invoice-value text-end text-md-center">{{lastInvoice?.amount | customCurrency }}</div>
                    </div>

                    <div class="d-flex d-md-grid flex-row-reverse">
                        <!-- <div *ngIf="lastInvoice?.invoicePaymentStatus?.statusTypeName != 'Pago'" class="col-6 col-md-12"> -->
                        <div *ngIf="showOptionsPayment()" class="col-6 col-md-12">
                            <div class="payment-options text-center text-md-center py-1">Pagar fatura com</div>
                            <div class="row payment-options-icons text-center p-1">
                                <div class="col-6 d-grid p-0 cursor-pointer" (click)="openModal(PaymentType.CREDITO)">
                                    <i class="bi bi-credit-card m-1"></i>
                                    <span>Crédito</span>
                                </div>

                                <div class="col-6 d-grid justify-content-center p-0 cursor-pointer" (click)="openModal(PaymentType.PIX)">
                                    <mat-icon class="pix-mat-icon m-1" svgIcon="pix-green"></mat-icon>
                                    <span>PIX</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-12 mt-4 text-md-center align-content-center" [ngClass]="lastInvoice?.invoicePaymentStatus?.statusTypeName == 'Pago'? 'col-12 mt-2':''">
                            <app-button [buttonOptions]="btnInvoiceDetailsOptions" (buttonClick)="goToInvoiceDetail(lastInvoice?.id)"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Card Dados de Sócio-->
        <div class="col-12 col-md-4">
            <div class="card partner-card">
                <div class="card-body row user-info">
                    <div class="card-title">Dados de Sócio</div>

                    <div class="col-12 col-md-12 py-1"><i class="bi bi-person-fill"></i><b> Nome:</b> {{pattern?.name}}</div>
                    <div class="col-12 col-md-12 py-1"><i class="bi bi-exclamation-circle-fill"></i><b> Situação:</b> {{pattern?.memberSituation?.name}}</div>
                    <div class="col-12 col-md-12 py-1"><i class="bi bi-calendar-fill"></i><b> Data de Associação:</b> {{pattern?.registrationDate | date:'dd/MM/yyyy' : '+0000'}}</div>
                    <div class="col-12 col-md-12 py-1"><i class="bi bi-envelope-fill"></i><b> E-mail:</b> {{pattern?.emails[0]?.email}}</div>
                    <div class="col-12 col-md-12 py-1"><i class="bi bi-telephone-fill"></i><b> Telefone:</b> {{pattern?.phones[0]?.number | phone}}</div>
                    <div class="col-12 col-md-12 py-1"><i class="bi bi-gender-ambiguous"></i><b> Gênero:</b> {{pattern?.gender === "Masculino" ? 'Masculino' : pattern?.gender === "Feminino" ? 'Feminino' : 'Não informado'}}</div>
                    <div class="col-12 py-1 d-none d-md-block"><i class="bi bi-house-door-fill"></i><b> Endereço:</b> {{this.getAddress()}}</div>

                    <app-button class="align-content-end mt-4" [buttonOptions]="btnUpdateDataOptions" (buttonClick)="goToPartnerData()"></app-button>

                </div>
            </div>
        </div>

        <!-- Card Faturas-->
        <div class="col-12 col-md-8">
            <app-invoice-table [memberPlanId]="memberPlanId" [invoiceList]="invoiceList"></app-invoice-table>
        </div>

        <!-- Card Meu Rank-->
        <div class="col-12 col-md">
            <div class="card rank-card">
                <div class="card-body row user-info">
                    <div class="card-title">Meu Rank</div>

                    <div class="col-12 col-md-12 py-1 "> <b>Sua posição atual no ranking é:</b> <span class="fw-medium"> 1º</span></div>
                    <div class="col-12 col-md-12 py-1 "> <b>Saldo de pontos:</b> <span class="fw-medium"> 500 pontos</span></div>
                    <div class="col-12 col-md-12 py-1 "> <b>Tendência: </b> <i class="bi bi-caret-up-fill"></i><span class="fw-medium up"> Subindo</span></div>
                    <div class="col-12 col-md-12 py-1 "> <b>Tempo no ranking atual:</b> <span class="fw-medium"> 92 dias</span></div>

                    <app-button class=" align-content-end mt-4" [buttonOptions]="btnRankDetailsOptions" (buttonClick)="rankDetail()"></app-button>

                </div>
            </div>
        </div>
        <div class="col-12 col-md">
            <div class="card experience-card">
                <div class="card-body d-grid">
                    <div class="card-title">Experiências</div>
                    <div class="row align-content-between">
                        @for (item of experiences; track $index) {
                            <div class="col-12 col-md-12 py-1 d-flex">
                                <div class="col-2 col-xxl-1">
                                    <img class="icon" style="height: 23px; width: 23px;" [src]="item.src" [alt]="'Imagem ' + item.name">
                                </div>
                                @if (item.description != '' && item.description != null) {
                                            <div class="col-6 col-xxl-7 ex-info px-1 user-info">{{item.description}}</div>
                                }
                                @if (item.requiredPoints != '' && item.requiredPoints != null) {
                                            <div class="col-4 points px-0 pe-2 text-end user-info"> <b>{{item.requiredPoints}} pontos</b></div>
                                }
                            </div>
                        }
                    </div> 

                    <app-button class="align-content-end mt-4" [buttonOptions]="btnExperienceOptions" (buttonClick)="goToExperiences()"></app-button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md">
            <div class="card partnership-card">
                <div class="card-body d-grid">
                    <div class="card-title">Parcerias e Cupons</div>

                    <div class="row align-content-between">
                        @for (item of partners; track $index) {
                        <div class="col-12 col-md-12 py-1 d-flex">
                            <div class="col-2 col-xxl-1">
                                <img class="icon" style="height: 23px; width: 23px;" [src]="item.src"  [alt]="'Imagem ' + item.partnerName">
                            </div>
                            @if (item.description != '' && item.description != null) {
                                        <div class="col-10 align-content-center col-xxl-7 partnership-info px-1 user-info">{{item.description}}</div>
                            }
                        </div>
                        }
                    </div>

                    <app-button class=" align-content-end mt-4" [buttonOptions]="btnPartnershipsCouponsOptions" (buttonClick)="showAllCoupons()"></app-button>

                </div>
            </div>
        </div>
    </div>
</div>
@if(paymentType){
<app-payment-invoice-modal
    #paymentModal
    [modalId]="modalId"
    [paymentType]="paymentType"
    [paymentInvoiceModalData]="paymentInvoiceModalData"
    (confirmActionChange)="onModalConfirm($event)"
></app-payment-invoice-modal>
}

<app-modal
[modalTitle]="'Termos de Uso'"
modalId="termsModal"
[size]="'modal-xl'"
[showCloseBtn]="false"
[scrollable]="true"
[modalBodyMessage]="termsModalBodyMessage"
modalConfirmLabel="Aceitar e Continuar"
[backdropType]="'static'"
(confirmActionChange)="onTermsModalConfirm()"></app-modal>
