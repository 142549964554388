import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ChargerService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,) {
      super(http, errorHandlerService);
    }

    payInvoice(data: any, invoiceId: string) {
      return this.put(`${this.env.publicApiSocioUrl}v1/socio/charger/invoice/${invoiceId}`, data);
    }

}
