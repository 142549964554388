export interface PaymentInfoModel {
    plan: string;
    frequency: string;
    invoiceRecurrence: boolean;
    formOfPayment: string;
    creditCard?: string;
    numberOfInstallments?: string;
    dependents?: string;
}

export interface CreditCardInfoModel {
    cardNumber: string;
    expirationDate: string;
    cpf: string;
    cvv: string;
    nameCard: string;
    cardOperator: string;
    cardIcon?: string;
}

export interface PaymentSummaryItem {
    planItemDescription: string;
    planItemlPrice: number;
    planItemDetail: string;
}

export enum PaymentType {
    PIX = 'PIX',
    CREDITO = 'Cartão de Crédito',
    CREDITO_ABREVIADO = 'C. de Crédito',
    BOLETO = 'boleto'
}

export enum PaymentFrequency {
    MENSAL = 'mensal',
    TRIMESTRAL = 'trimestral',
    SEMESTRAL = 'semestral',
    ANUAL = 'anual'
}
